import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';

const initialState = {
  dialog: {
    isOpen: false,
    title: '',
    type: '',
    description: '',
    confirmation: false,
    props: null,
    fullScreen: false,
  },
  snackbar: {
    isOpen: false,
    type: 'success',
    text: '',
    static: false,
    action: null,
  },
};

const reducer = createReducer(initialState, (builder) => builder
  .addCase(actions.openDialog, (state, { payload }) => {
    state.dialog.isOpen = true;
    state.dialog.title = payload.title;
    state.dialog.type = payload.type;
    state.dialog.props = payload.props;
    state.dialog.description = payload.description;
    state.dialog.confirmation = payload.confirmation;
    state.dialog.fullScreen = payload.fullScreen;
  })
  .addCase(actions.closeDialog, (state) => {
    state.dialog = initialState.dialog;
    state.isLoading = false;
  })
  .addCase(actions.openSnackbar, (state, { payload }) => {
    state.snackbar.isOpen = true;
    state.snackbar.text = payload.text;
    state.snackbar.type = payload.type || 'success';
    state.snackbar.static = payload.static;
    state.snackbar.action = payload.action;
  })
  .addCase(actions.closeSnackbar, (state) => {
    const snackbarType = state.snackbar.type;
    const { type, ...snackbar } = initialState.snackbar;
    state.snackbar = snackbar;
    state.snackbar.type = snackbarType;
  }));

export default reducer;
