import { useState } from 'react';
import { Button, DialogActions, TextField } from '@mui/material';
import { Flex } from '@synthetica-ai/components';

const AddJob = ({
  closeDialog,
  props,
}) => {
  const [categoryA, setCategoryA] = useState(props?.category_a);
  const [canEdit, setCategoryB] = useState(props?.category_b);
  const [value, setValue] = useState('');

  return (
    <Flex
      center
      gap="2rem"
      direction="column"
      width="100%"
    >
      <Flex
        direction="column"
        gap="2rem"
      >
        <TextField
          value={categoryA}
          disabled={!!props?.category_a}
          label="Super Category"
          size="small"
          variant="standard"
          onChange={(ev) => setCategoryA(ev.target.value)}
        />
        <TextField
          value={canEdit}
          disabled={!!props?.category_b}
          size="small"
          label="Category"
          variant="standard"
          sx={{ width: '22ch', ml: 'auto' }}
          onChange={(ev) => setCategoryB(ev.target.value)}
        />
        <TextField
          value={value}
          size="small"
          label="Job"
          variant="standard"
          sx={{ width: '20ch', ml: 'auto' }}
          onChange={(ev) => setValue(ev.target.value)}
        />
      </Flex>
      <DialogActions sx={{ ml: 'auto', mt: '1rem' }}>
        <Button
          color="primary"
          variant="outlined"
          onClick={closeDialog}
        >
          Cancel
        </Button>

        <Button
          color="primary"
          variant="contained"
          disabled={!(categoryA && canEdit && value)}
          onClick={() => props.onClick(categoryA, canEdit, value)}
        >
          Confirm
        </Button>
      </DialogActions>
    </Flex>
  );
};

export default AddJob;
