import {
  Autocomplete,
  Button,
  DialogActions,
  TextField,
} from '@mui/material';

import { Flex } from '@synthetica-ai/components';
import { withProps } from '@synthetica-ai/system';
import { categories, updateOtherCosts } from 'models/drydock';
import { useEffect, useState } from 'react';
import { AUTOCOMPLETE, fields, TEXTFIELD } from './helper';

const AddOtherCost = ({
  closeDialog,
  categories,
  updateOtherCosts,
  props,
}) => {
  const [state, setState] = useState({ id: null, category_id: props?.category_id });

  const addNewCost = () => {
    updateOtherCosts([state]);
  };

  return (
    <Flex center gap="1rem" direction="column">
      {fields.map(({
        field,
        path,
        label,
        ...rest
      }) => {
        if (field == AUTOCOMPLETE) {
          return (
            <Autocomplete
              key={label}
              options={categories}
              disabled={!!props}
              size="small"
              sx={{ width: '20ch' }}
              getOptionLabel={(datum) => datum.name}
              onChange={(_, value) => setState((prev) => ({ ...prev, [path]: value.id }))}
              value={categories.find((cat) => cat.id == state[path]) || null}
              renderInput={(params) => <TextField {...params} label={label} />}
            />
          );
        }

        if (field == TEXTFIELD) {
          return (
            <TextField
              key={label}
              size="small"
              sx={{ width: '20ch' }}
              label={label}
              value={state[path] || ''}
              onChange={(ev) => setState((prev) => ({ ...prev, [path]: ev.target.value }))}
              {...rest}
            />
          );
        }
        return null;
      })}

      <DialogActions>
        <Button
          color="primary"
          variant="outlined"
          onClick={closeDialog}
        >
          Cancel
        </Button>

        <Button
          disabled={!state.category_id}
          onClick={() => addNewCost()}
          color="primary"
          variant="contained"
        >
          Add
        </Button>
      </DialogActions>
    </Flex>
  );
};

export default withProps({
  categories,
  updateOtherCosts,
})(AddOtherCost);
