import { createReducer, current } from '@reduxjs/toolkit';
import { USER } from '@synthetica-ai/constants';
import { localStorageValue } from '@synthetica-ai/utils/storage';
import { uniqBy } from 'lodash';
import { setDrydockDetails } from 'models/worklist';

import * as actions from './actions';

const initState = {
  vessels: [],
  shipyards: [],
  drydocks: [],
  selectedRoute: 'home',
  isLoading: false,
  uuid: null,
  hasEditRights: null,
  loadingState: {
    prices: false,
    summary: false,
  },
};

const user = localStorageValue(USER).get();

const canEdit = (state, payload) => {
  const drydocks = current(state.drydocks);
  const drydock = drydocks.find((drydock) => drydock.uuid == payload);
  const isAdmin = user.role == 'admin';
  const canEdit = user.fleet?.includes(drydock?.vessel.fleet)
    || user.vessels?.includes(drydock?.vessel.name)
    || user.drydock_ids?.includes(drydock?.uuid)
    || (!user.fleet && !user.vessels && !user.drydock_ids);

  return isAdmin && canEdit;
};

const reducer = createReducer(initState, (builder) => builder
  .addCase(actions.saveUuid, (state, { payload }) => {
    state.uuid = payload;
    state.hasEditRights = canEdit(state, payload);
  })
  .addCase(setDrydockDetails, (state, { payload }) => {
    state.hasEditRights = canEdit(state, payload);
  })
  .addCase(actions.initiateDrydock, (state) => {
    state.isLoading = true;
  })
  .addCase(actions.initiateDrydock.success, (state) => {
    state.isLoading = false;
  })
  .addCase(actions.initiateDrydock.fail, (state) => {
    state.isLoading = false;
  })
  .addCase(actions.getVessels.success, (state, { payload }) => {
    state.vessels = uniqBy(payload.vessels, (vessel) => vessel.name);
    state.shipyards = payload.shipyards;
  })
  .addCase(actions.getDrydocks.success, (state, { payload }) => {
    state.drydocks = payload.map(({ quoted_dd, ...rest }) => ({
      quoted_dd: Number(quoted_dd)?.toFixed(0),
      ...rest,
    }));
  })
  .addCase(actions.updateRoute, (state, { payload }) => {
    state.selectedRoute = payload;
  })
  .addCase(actions.setAdminEditRights, (state, { payload }) => {
    state.hasEditRights = canEdit(state, payload.uuid) && !payload.finalized;
  })
  .addCase(actions.exportSummary, (state) => {
    state.loadingState.summary = true;
  })
  .addCase(actions.exportSummary.success, (state) => {
    state.loadingState.summary = false;
  })
  .addCase(actions.exportSummary.fail, (state) => {
    state.loadingState.summary = false;
  })
  .addCase(actions.exportCsv, (state) => {
    state.loadingState.prices = true;
  })
  .addCase(actions.exportCsv.success, (state) => {
    state.loadingState.prices = false;
  })
  .addCase(actions.exportCsv.fail, (state) => {
    state.loadingState.prices = false;
  }));

export default reducer;
