import { Typography, Breadcrumbs, Link } from '@mui/material';

function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

const BasicBreadcrumbs = ({ }) => (
  <div role="presentation" style={{ marginBottom: '1rem' }} >
    <Breadcrumbs aria-label="breadcrumb" separator="›">
      <Link underline="hover" color="#0c74d5" href="/">
        Drydock Management
      </Link>
      <Typography color="text.primary"><strong style={{ color: '#003349' }}>Saved DD Specification</strong></Typography>
    </Breadcrumbs>
  </div>
);

export default BasicBreadcrumbs;
