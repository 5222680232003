import {
  ListItem,
  ListItemText,
  Paper,
} from '@mui/material';
import { Flex } from '@synthetica-ai/components';
import { withProps } from '@synthetica-ai/system';
import { startCase, sortBy } from 'lodash';
import { closeDialog } from 'models/ui';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

const sortData = (data) => sortBy(data, (datum) => {
  const [vessel, _, year] = datum.project_id.split('-');
  return `${year} ${vessel}`;
});

const List = ({ data, history, closeDialog }) => {
  const onClick = (uuid) => {
    history.push(`drydock/${uuid}`);
    closeDialog();
  };

  return (
    <Flex direction="column" gap="1rem">
      {sortData(data).map((datum, index) => (
        <Paper
          key={index}
          onClick={() => onClick(datum.uuid)}
          elevation={2}
          style={{ cursor: 'pointer' }}
        >
          <ListItem>
            <ListItemText
              primary={`${startCase(datum.project_id)}`}
            />
          </ListItem>
        </Paper>
      ))}
    </Flex>
  );
};

export default compose(
  withRouter,
  withProps({ closeDialog }),
)(List);
