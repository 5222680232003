import {
  filter,
  ignoreElements,
  map,
  tap,
} from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';

import { httpRequest } from '@synthetica-ai/system';

import { closeDialog, openSnackbar } from 'models/ui';
import { format } from 'date-fns';
import { downloadTutorial, getDrydocks } from 'models/app';
import { constructDate } from 'utils/date';
import * as actions from './actions';
import * as services from './services';

const getDateOnly = (date = new Date()) => format(new Date(date), 'yyyy-MM-dd');

const getDrydockDetailsEpic = (action$) => action$.pipe(
  ofType(actions.getDrydocksDetails.type),
  httpRequest(action$, services.drydockDetails),
);

const updateJobsEpic = (action$, value$) => action$.pipe(
  ofType(actions.updateJobs.type),
  map(() => value$.value.worklist.jobs.entities),
  map((payload) => {
    const arr = Object.entries(payload || {})
      .map(([key, values]) => [key, {
        ...values,
        quantity: values.quantity ? values.quantity : 0,
        discount: values.discount ? values.discount : 0,
        gross_unit_price: values.gross_unit_price ? values.gross_unit_price : 0,
        net_unit_price: values.net_unit_price ? values.net_unit_price : 0,
        completion: values?.completion ? values.completion : 0,
      }]);
    return Object.fromEntries(arr);
  }),
  httpRequest(action$, services.jobsService,
    {
      additionalSuccessActions: () => [
        openSnackbar({ text: 'Job Updated' }),
      ],
    }),
);

const uploadImagesEpic = (action$) => action$.pipe(
  ofType(actions.uploadImages.type),
  map(({ payload }) => [payload.id, payload.files]),
  httpRequest(action$, services.uploadImages, {
    additionalSuccessActions: () => [
      openSnackbar({ text: 'Images Uploaded' }),
    ],
  }),
);

const deleteImageEpic = (action$) => action$.pipe(
  ofType(actions.deleteImage.type),
  httpRequest(action$, services.deleteImage, {
    additionalSuccessActions: () => [
      openSnackbar({ text: 'Image Deleted' }),
    ],
  }),
);

const updateProjectDetailsEpic = (action$) => action$.pipe(
  ofType(actions.updateProjectDetails.type),
  map(({ payload }) => [payload.uuid, payload.data]),
  httpRequest(action$, services.updateProjectDetails, {
    additionalSuccessActions: () => [
      openSnackbar({ text: 'Project Details Updated' }),
    ],
  }),
);

const addJobEpic = (action$) => action$.pipe(
  ofType(actions.addJob.type),
  map(({ payload }) => {
    const { uuid, data } = payload;
    return [uuid, data];
  }),
  httpRequest(action$, services.addJob, {
    additionalSuccessActions: () => [
      closeDialog(),
      openSnackbar({ text: 'Job Added' }),
    ],
  }),
);

const getJobImagesEpic = (action$) => action$.pipe(
  ofType(actions.getJobImages.type),
  httpRequest(action$, services.getJobImages),
);

const getShipyardFilesEpic = (action$) => action$.pipe(
  ofType(actions.getShipyardFiles.type),
  httpRequest(action$, services.getShipyardFiles),
);

const deleteFileEpic = (action$) => action$.pipe(
  ofType(actions.deleteFile.type),
  httpRequest(action$, services.deleteFile, {
    additionalSuccessActions: () => [
      openSnackbar({ text: 'File Deleted' }),
    ],
  }),
);

const uploadShipyardFilesEpic = (action$) => action$.pipe(
  ofType(actions.uploadShipyardFiles.type),
  map(({ payload }) => [payload.uuid, payload.data, payload.type]),
  httpRequest(action$, services.uploadShipyardFiles, {
    additionalSuccessActions: () => [
      openSnackbar({ text: 'Shipyard File Uploaded' }),
      closeDialog(),
    ],
  }),
);

const getDDTimesEpic = (action$) => action$.pipe(
  ofType(actions.getDDTimes.type),
  httpRequest(action$, services.getDDTimes),
);

const getDDTimesSuccessEpic = (action$) => action$.pipe(
  ofType(actions.getDDTimes.success.type),
  map(({ payload }) => payload.times?.filter((datum) => datum.off_time)),
  map((times) => actions.fillOffhireData(times)),
);

const fillOffhireData = (action$) => action$.pipe(
  ofType(actions.fillOffhireData.type),
  filter(({ payload }) => payload.length > 0),
  map(({ payload }) => (payload.length == 2
    ? actions.updateDDTimes([
      { ...payload[0], datetime: getDateOnly(payload[0].datetime) },
      { ...payload[1], datetime: getDateOnly(payload[1].datetime) },
    ])
    : actions.updateDDTimes([
      { ...payload[0], datetime: getDateOnly(payload[0].datetime) },
      { datetime: getDateOnly(), off_hire_day: true },
    ]))),
);

const finalizeOffHire = (action$) => action$.pipe(
  ofType(actions.finalizeOffhireData.type),
  map(({ payload }) => [payload.start, payload.end]),
  map(([start, end]) => actions.updateDDTimes([
    { ...start, datetime: getDateOnly(start.datetime) },
    { ...end, datetime: getDateOnly(end.datetime) },
  ])),
);

const updateDDTimesEpic = (action$, value$) => action$.pipe(
  ofType(actions.updateDDTimes.type),
  map(({ payload }) => payload.map(({ id, ...rest }) => ({
    datetime: id && format(constructDate(id), 'yyyy-MM-dd'),
    ...rest,
  }))),
  map((data) => [data, value$.value.worklist.details.uuid]),
  httpRequest(action$, services.updateDDTimes, {
    additionalSuccessActions: () => [
      getDrydocks(),
      openSnackbar({ text: 'Updated DD Times' }),
      closeDialog(),
    ],
  }),
);

const finalizeDDepic = (action$, value$) => action$.pipe(
  ofType(actions.finalizeDD.type),
  map(() => value$.value.worklist.details.uuid),
  httpRequest(action$, services.finalizeDD, {
    additionalSuccessActions: (payload) => [
      openSnackbar({ text: payload.message }),
    ],
    additionalFailureActions: () => [
      openSnackbar({ text: 'Required files not found.', type: 'error' }),
    ],
  }),
);

const downloadFileEpic = (action$, value$) => action$.pipe(
  ofType(actions.downloadFile.type),
  map(({ payload }) => [payload, value$.value.worklist.details.uuid]),
  httpRequest(action$, services.downloadFile),
);

const uploadTimeplanFileEpic = (action$, value$) => action$.pipe(
  ofType(actions.uploadTimeplanFile.type),
  map(({ payload }) => [payload, value$.value.worklist.details.uuid]),
  httpRequest(action$, services.uploadTimeplanFile, {
    additionalSuccessActions: () => [
      openSnackbar({ type: 'success', text: 'File uploaded!' }),
      closeDialog(),
    ],
  }),
);

const downloadTimeplanFileEpic = (action$, value$) => action$.pipe(
  ofType(actions.downloadTimeplanFile.type),
  map(({ payload }) => [payload, value$.value.worklist.details.uuid]),
  httpRequest(action$, services.downloadTimeplanFile),
);

const deleteTimeplanFileEpic = (action$) => action$.pipe(
  ofType(actions.deleteTimeplanFile.type),
  httpRequest(action$, services.deleteTimeplanFile, {
    additionalSuccessActions: () => [
      openSnackbar({ type: 'success', text: 'File deleted!' }),
    ],
  }),
);

const downloadFileSuccessEpic = (action$) =>
  action$.pipe(
    ofType(
      actions.downloadFile.success.type,
      downloadTutorial.success.type,
      actions.downloadTimeplanFile.success.type,
    ),
    tap(({ payload }) => {
      const imageLink = document.getElementById('image-link');
      imageLink.href = payload.file.file;
      imageLink.download = payload.file.filename;
      imageLink.click();
    }),
    ignoreElements(),
  );

const getHistoryJobsEpic = (action$, value$) =>
  action$.pipe(
    ofType(actions.getHistoryJobs.type),
    map(() => value$.value.worklist.details.uuid),
    httpRequest(action$, services.getHistoryJobs),
  );

const revertHistoryEpic = (action$, value$) => action$.pipe(
  ofType(actions.revertHistory.type),
  map(({ payload }) => [payload, value$.value.worklist.details.uuid]),
  httpRequest(action$, services.revertHistory, {
    additionalSuccessActions: () => [
      openSnackbar({ text: 'Reverted History' }),
      actions.getHistoryJobs(),
    ],
  }),
);

const addJobSuccessEpic = (action$) => action$.pipe(
  ofType(actions.addJob.success.type),
  tap(({ sourcePayload }) => sourcePayload.onSuccess()),
  ignoreElements(),
);

export default combineEpics(
  getDrydockDetailsEpic,
  updateProjectDetailsEpic,
  updateJobsEpic,
  uploadImagesEpic,
  getJobImagesEpic,
  addJobEpic,
  deleteImageEpic,
  getShipyardFilesEpic,
  uploadShipyardFilesEpic,
  deleteFileEpic,
  getDDTimesEpic,
  getDDTimesSuccessEpic,
  fillOffhireData,
  finalizeOffHire,
  updateDDTimesEpic,
  downloadFileEpic,
  downloadFileSuccessEpic,
  uploadTimeplanFileEpic,
  downloadTimeplanFileEpic,
  deleteTimeplanFileEpic,
  finalizeDDepic,
  getHistoryJobsEpic,
  revertHistoryEpic,
  addJobSuccessEpic,
);
