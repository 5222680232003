import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { ModelProvider } from '@synthetica-ai/system';

import { models } from 'models';

import './root.css';

const Providers = ({
  theme,
  children,
}) => (
  <ModelProvider model={models}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          {children}
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </ModelProvider>
);

export default Providers;
