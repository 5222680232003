import {
  TableCell,
  TableRow,
  TableHead as MaterialTableHead,
} from '@mui/material';

import { useStyles } from './styles';

const TableHead = ({ headerGroups }) => {
  const classes = useStyles();
  return (
    <MaterialTableHead className={classes.head}>
      {headerGroups.map((headerGroup) => (
        <TableRow {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column) => (
            <TableCell
              className={classes.headCell}
              {...column.getHeaderProps({ style: column.style })}
            >
              <div style={{ display: 'flex' }}>
                {column.render('Header')}
              </div>
              {column.filter ? column.render('Filter') : null}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </MaterialTableHead>
  );
};

export default TableHead;
