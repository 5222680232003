import { useEffect } from 'react';
import { CssBaseline } from '@mui/material';
import { withProps } from '@synthetica-ai/system';
import { LoadingCover } from '@synthetica-ai/components';

import { Routes } from 'routes';
import { isAuthenticated, validateToken } from 'models/user';

const App = ({ validateToken, isAuthenticated }) => {
  useEffect(() => {
    validateToken();
  }, []);

  return (
    <LoadingCover
      full
      isLoading={!isAuthenticated}
      text="Waiting for Authentication..."
    >
      <CssBaseline />
      <Routes />
      <a id="image-link" style={{ visibility: 'hidden' }} />
    </LoadingCover>
  );
};

export default withProps({
  validateToken,
  isAuthenticated,
})(App);
