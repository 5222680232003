import { from, of } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';

import {
  map,
  mergeMap,
  tap,
  ignoreElements,
  filter,
  scan,
  takeLast,
  catchError,
} from 'rxjs/operators';

import { httpRequest } from '@synthetica-ai/system';
import { localStorageValue } from '@synthetica-ai/utils/storage';
import { startApplication } from 'models/app';
import {
  FAILED_REQUESTS,
} from 'constants/storage';

import {
  REFRESH_TOKEN,
  TOKEN,
  USER,
} from '@synthetica-ai/constants';

import { ajax } from 'rxjs/ajax';
import { openSnackbar } from 'models/ui';
import * as actions from './actions';
import * as services from './services';

const user = localStorageValue(USER);
const token = localStorageValue(TOKEN);
const refreshToken = localStorageValue(REFRESH_TOKEN);
const failedRequests = localStorageValue(FAILED_REQUESTS);

const validateTokenEpic = (action$) => action$.pipe(
  ofType(actions.validateToken.type),
  map(() => {
    const { href } = window.location;
    const url = new URL(href);
    const params = new URLSearchParams(url.search);
    const token = params.get('token');
    return token;
  }),
  map((token) => {
    if (!navigator.onLine) {
      return actions.checkToken.success({});
    }

    if (token) {
      return actions.reIssueToken(token);
    }
    return actions.checkToken();
  }),
);

const reIssueTokenEpic = (action$) => action$.pipe(
  ofType(actions.reIssueToken.type),
  httpRequest(action$, services.reIssueToken),
);

const reIssueTokenSuccessEpic = (action$) => action$.pipe(
  ofType(actions.reIssueToken.success.type),
  map(({ payload }) => ({
    ...payload,
    user: {
      ...payload.user,
      access: payload.user.access.split(','),
      fleet: payload.user?.fleet?.split(','),
      vessels: payload.user?.vessels?.split(','),
      drydock_ids: payload.user?.drydock_ids?.split(','),
    },
  })),
  tap((payload) => {
    user.set(payload.user);
    token.set(payload.auth.token);
    refreshToken.set(payload.auth.refreshToken);
    window.history.pushState({}, '', '/', '');
    window.location.reload();
  }),
  ignoreElements(),
);

const checkTokenEpic = (action$) => action$.pipe(
  ofType(actions.checkToken.type),
  httpRequest(action$, services.checkToken),
);

const changeTokenSuccessEpic = (action$) => action$.pipe(
  ofType(actions.checkToken.success.type),
  tap(({ payload }) => token.set(payload.token)),
  mergeMap(() => [
    actions.updateUser({ isAuthenticated: true, info: user.get() }),
    startApplication(),
  ]),
);

const logoutEpic = (action$) => action$.pipe(
  ofType(actions.logout.type),
  httpRequest(action$, services.logout),
);

const redirectToWebsiteEpic = (action$) => action$.pipe(
  ofType(
    actions.logout.success.type,
    actions.checkToken.fail.type,
    actions.getAuthToken.fail.type,
    actions.reIssueToken.fail.type,
  ),
  tap(() => {
    localStorage.clear();
    process.env.NODE_ENV == 'production' && window.location.replace('https://synthetica.ai/');
  }),
  ignoreElements(),
);

const saveFailedRequestsEpic = (action$) => action$.pipe(
  ofType(actions.saveFailedRequests.type),
  filter(({ payload }) => {
    const domainParts = payload.url.split('/');
    const httpMethod = payload.method;

    return domainParts.at(-1) == 'jobs' || (httpMethod.toLowerCase() == 'post' && domainParts.at(-2) == 'files');
  }),
  tap(({ payload }) => {
    const requests = failedRequests.get();
    failedRequests.set([...(requests || []), payload]);
  }),
  ignoreElements(),
);

const syncFailedRequestsEpic = (action$) => action$.pipe(
  ofType(actions.syncFailedRequests.type),
  filter(() => navigator.onLine && failedRequests.get()),
  map(() => failedRequests.get()?.map((options) => ({
    ...options,
    headers: {
      authorization: `Bearer ${token.get()}`,
    },
  }))),
  mergeMap((requests) => from(requests).pipe(
    mergeMap((request) => ajax(request)),
    map(({ response }) => response.success),
    scan((a, c) => a && c, true),
    takeLast(1),
  )),
  tap((succeeded) => succeeded && failedRequests.remove()),
  mergeMap((succeeded) => (succeeded
    ? [openSnackbar({ type: 'success', text: 'The synchronisation process was successful!' })]
    : [
      openSnackbar({
        type: 'warning',
        text: 'Unsuccessful synchronisation process, will retry shortly!',
      }),
    ])),
  catchError(() => of(openSnackbar({
    type: 'warning',
    text: 'Unsuccessful synchronisation process, will retry shortly!',
  }))),
);

export default combineEpics(
  logoutEpic,
  redirectToWebsiteEpic,
  validateTokenEpic,
  checkTokenEpic,
  changeTokenSuccessEpic,
  reIssueTokenEpic,
  reIssueTokenSuccessEpic,
  saveFailedRequestsEpic,
  syncFailedRequestsEpic,
);
