export const createColumns = () => [
  {
    Header: 'Name',
    accessor: 'job_summary',
  },
  {
    Header: 'Reason',
    accessor: 'reason',
  },
  {
    Header: 'Quantity',
    accessor: 'quantity',
  },
  {
    Header: 'Gross Unit Price',
    accessor: 'gross_unit_price',
  },
  {
    Header: 'Net Unit Price',
    accessor: 'net_unit_price',
  },
  {
    Header: 'Total Net Item Price',
    accessor: 'total_net_item_price',
  },
  {
    Header: 'For Indication',
    accessor: 'for_indication_works',
    Cell: ({ value }) => (value ? 'Yes' : 'No'),
  },
];
