import { localStorageValue } from '@synthetica-ai/utils/storage';
import { REFRESH_TOKEN, TOKEN, USER } from '@synthetica-ai/constants';
import { AUTH_URL } from 'constants/origins';
import { createEndpoint } from 'utils/services';

const refreshToken = localStorageValue(REFRESH_TOKEN);
const user = localStorageValue(USER);
const token = localStorageValue(TOKEN);

export const logout = () => ({
  url: createEndpoint(AUTH_URL, 'logout'),
  method: 'POST',
  body: {
    refreshToken: refreshToken.get(),
  },
});

export const getAuthToken = () => ({
  url: createEndpoint(AUTH_URL, 'token'),
  method: 'POST',
  body: {
    refreshToken: refreshToken.get(),
    username: user.get()?.username,
  },
});

export const checkToken = () => ({
  url: createEndpoint(AUTH_URL, 'checkToken'),
  method: 'POST',
  body: {
    refreshToken: refreshToken.get(),
    token: token.get(),
    project: 'ddcost',
  },
});

export const reIssueToken = ({ payload }) => ({
  url: createEndpoint(AUTH_URL, 're-issue'),
  method: 'POST',
  body: {
    token: payload,
  },
});
