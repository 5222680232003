export const fileListToBase64 = async (file) => {
  function getBase64(file) {
    const reader = new FileReader();
    return new Promise((resolve) => {
      reader.onload = (ev) => {
        resolve(ev.target.result);
      };
      reader.readAsDataURL(file);
    });
  }
  const promises = [];
  promises.push(getBase64(file));
  return Promise.all(promises);
};
