import {
  Button,
  DialogActions,
} from '@mui/material';

import { Flex } from '@synthetica-ai/components';
import { withProps } from '@synthetica-ai/system';
import { deleteJob } from 'models/drydock';

const DeleteJob = ({
  props,
  closeDialog,
  deleteJob,
}) => (
  <Flex center direction="column" style={{ width: '100%' }}>
    <DialogActions sx={{ ml: 'auto', mt: '1rem' }}>
      <Button
        color="primary"
        variant="outlined"
        onClick={closeDialog}
      >
        Cancel
      </Button>

      <Button
        color="primary"
        variant="contained"
        onClick={() => deleteJob({ ids: props.jobsIds })}
      >
        Confirm
      </Button>
    </DialogActions>
  </Flex>
);

export default withProps({
  deleteJob,
})(DeleteJob);
