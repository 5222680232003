import { adapter } from './reducer';

export const times = ({ worklist }) => worklist.times;
export const details = ({ worklist }) => worklist.details;
export const files = ({ worklist }) => worklist.files;
export const drydockDetails = ({ worklist }) => worklist.drydockDetails;
export const jobs = ({ worklist }) => adapter.getSelectors().selectAll(worklist.jobs);
export const photos = ({ worklist }) => adapter.getSelectors().selectAll(worklist.photos);
export const tableTimes = ({ worklist }) => adapter.getSelectors().selectAll(worklist.tableTimes);
export const historyJobs = ({ worklist }) => worklist.historyJobs;
export const updatedJobs = ({ worklist }) => worklist.updatedJobs;
