import {
  ignoreElements,
  map,
  mergeMap,
  tap,
} from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';

import { httpRequest } from '@synthetica-ai/system';

import download from 'downloadjs';

import { closeDialog, openDialog, openSnackbar } from 'models/ui';
import { updateProjectDetails } from 'models/worklist';
import { getDrydocks } from 'models/app';
import { percentageIds } from 'constants/ids';

import { NOT_IMPORTED_JOBS } from 'constants/dialog';
import * as actions from './actions';
import * as services from './services';

const getDrydockEpic = (action$) => action$.pipe(
  ofType(actions.getDrydock.type),
  httpRequest(action$, services.getDrydock),
);

const updateDrydockEpic = (action$, value$) => action$.pipe(
  ofType(actions.updateDrydock.type),
  map(({ payload }) => {
    const jobs = Object.values(value$.value.drydock.defaultJobs.entities);
    const arr = payload
      .map((values) => ({
        ...values,
        quantity: values.quantity ? values.quantity : 0,
        gross_unit_price: values.gross_unit_price ? values.gross_unit_price : 0,
        net_unit_price: values.net_unit_price ? values.net_unit_price : 0,
        total_net_item_price: values.total_net_item_price ? values.total_net_item_price : 0,
      }))
      .map(function removeTotalNetPrice({ total_net_item_price, ...rest }) {
        const job = jobs.find((job) => job.id == rest.id);
        return {
          total_net_item_price,
          ...rest,
        };
      });

    const { uuid } = value$.value.app;
    return [uuid, arr];
  }),
  httpRequest(action$, services.updateDrydock, {
    additionalSuccessActions: () => [
      openSnackbar({ text: 'Job Updated' }),
    ],
  }),
);

const updateDrydockSuccessEpic = (action$, value$) => action$.pipe(
  ofType(
    actions.updateDrydock.success.type,
    actions.addJob.success.type,
    updateProjectDetails.success.type,
  ),
  map(() => value$.value.app.uuid),
  mergeMap((uuid) => [
    actions.getDrydock(uuid),
    getDrydocks(),
  ]),
);

const deleteJobEpic = (action$) => action$.pipe(
  ofType(actions.deleteJob.type),
  httpRequest(action$, services.deleteJob, {
    additionalSuccessActions: () => [
      closeDialog(),
      openSnackbar({ text: 'Job Deleted' }),
    ],
  }),
);

const addJobEpic = (action$, value$) => action$.pipe(
  ofType(actions.addJob.type),
  map(({ payload }) => {
    const { uuid } = value$.value.app;
    return [uuid, payload];
  }),
  httpRequest(action$, services.addJob, {
    additionalSuccessActions: () => [
      closeDialog(),
      openSnackbar({ text: 'Job Added' }),
    ],
  }),
);

const finalizeDrydockEpic = (action$, value$) => action$.pipe(
  ofType(actions.finalizeDrydock.type),
  map(() => value$.value.app.uuid),
  httpRequest(action$, services.finalizeDrydock, {
    additionalSuccessActions: () => [
      openSnackbar({ text: 'Drydock Finalized' }),
      closeDialog(),
    ],
  }),
);

const updateDiscountEpic = (action$, value$) => action$.pipe(
  ofType(actions.updateDiscount.type),
  map(({ payload }) => [value$.value.app.uuid, payload]),
  httpRequest(action$, services.updateDiscount, {
    additionalSuccessActions: () => [
      openSnackbar({ text: 'Discount Updated' }),
    ],
  }),
);

const getPaintAreasEpic = (action$) => action$.pipe(
  ofType(actions.getPaintAreas.type),
  httpRequest(action$, services.getPaintAreas),
);

const uploadCsvEpic = (action$, value$) => action$.pipe(
  ofType(actions.uploadCsv.type),
  map(({ payload }) => [payload, value$.value.app.uuid]),
  httpRequest(action$, services.uploadCsv, {
    additionalSuccessActions: () => [
      openSnackbar({ text: 'File has been successfully uploaded!' }),
    ],
    additionalFailureActions: () => [
      openSnackbar({ type: 'error', text: 'Error during file upload!' }),
      closeDialog(),
    ],
  }),
);

const uploadCsvSuccessEpic = (action$) => action$.pipe(
  ofType(actions.uploadCsv.success.type),
  map(({ payload }) => (payload?.length > 0
    ? openDialog({ type: NOT_IMPORTED_JOBS, props: payload, fullScreen: true })
    : closeDialog())),
);

const updateShipyardPriceEpic = (action$, value$) => action$.pipe(
  ofType(actions.updateShipyardPrice.type),
  map(({ payload }) => [payload, value$.value.app.uuid]),
  httpRequest(action$, services.updateShipyardPrice, {
    additionalSuccessActions: (payload) => [
      openSnackbar({ text: payload }),
      closeDialog(),
      actions.getDrydock(value$.value.app.uuid),
      getDrydocks(),
    ],
    additionalFailureActions: () => [
      openSnackbar({ type: 'error', text: 'Error during file upload!' }),
      closeDialog(),
    ],
  }),
);

const getOtherCostCategoriesEpic = (action$) => action$.pipe(
  ofType(actions.getOtherCostCategories.type),
  httpRequest(action$, services.getOtherCostCategories),
);

const getOtherCostsEpic = (action$, value$) => action$.pipe(
  ofType(actions.getOtherCosts.type),
  map(() => value$.value.app.uuid),
  httpRequest(action$, services.getOtherCosts),
);

const updateOtherCostsEpic = (action$, value$) => action$.pipe(
  ofType(actions.updateOtherCosts.type),
  map(({ payload }) => [payload, value$.value.app.uuid]),
  httpRequest(action$, services.updateOtherCosts, {
    additionalSuccessActions: () => [
      closeDialog(),
      openSnackbar({ text: 'Updated' }),
    ],
  }),
);

const deleteOtherCostEpic = (action$, value$) => action$.pipe(
  ofType(actions.deleteOtherCost.type),
  map(({ payload }) => [payload, value$.value.app.uuid]),
  httpRequest(action$, services.deleteOtherCosts, {
    additionalSuccessActions: () => [
      closeDialog(),
      openSnackbar({ text: 'Deleted' }),
    ],
  }),
);

const exportOtherCostsCsvEpic = (action$, value$) => action$.pipe(
  ofType(actions.exportOtherCostsCsv.type),
  map(({ payload }) => {
    const { uuid } = value$.value.app;
    return [uuid, payload];
  }),
  // tap((data) => console.log(data)),
  httpRequest(action$, services.exportOtherCostsCsv, {
    additionalSuccessActions: () => [
      closeDialog(),
    ],
  }),
);

const exportOtherCostsCsvEpicSuccessEpic = (action$) => action$.pipe(
  ofType(actions.exportOtherCostsCsv.success.type),
  tap(({ payload }) => {
    download(atob(payload.file), payload.filename, 'application/csv');
  }),
  ignoreElements(),
);

export default combineEpics(
  getDrydockEpic,
  updateDrydockEpic,
  deleteJobEpic,
  addJobEpic,
  finalizeDrydockEpic,
  updateDiscountEpic,
  uploadCsvEpic,
  uploadCsvSuccessEpic,
  getPaintAreasEpic,
  updateShipyardPriceEpic,
  updateDrydockSuccessEpic,
  getOtherCostCategoriesEpic,
  getOtherCostsEpic,
  updateOtherCostsEpic,
  deleteOtherCostEpic,
  exportOtherCostsCsvEpic,
  exportOtherCostsCsvEpicSuccessEpic,
);
