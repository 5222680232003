import Home from './home';
import Drydock from './drydock';
import Jobs from './jobs';
import Worklist from './worklist';

const routes = {
  home: {
    id: 'home',
    path: '/',
    label: 'Home',
    exact: true,
    component: Home,
    isPrivate: true,
    enabled: true,
  },
  drydock: {
    path: '/drydock/:uuid/:projectId?',
    component: Drydock,
    isPrivate: true,
    enabled: true,
  },
  jobs: {
    id: 'jobs',
    path: '/jobs/:uuid/:firstLevel?/:secondLevel?/:thirdLevel?',
    label: 'Jobs',
    component: Jobs,
    isPrivate: true,
    enabled: true,
  },
  worklist: {
    id: 'worklist',
    path: '/follow-up',
    label: 'Worklist',
    component: Worklist,
    isPrivate: true,
    enabled: true,
  },
};

export const routesList = Object.fromEntries(
  Object
    .entries(routes)
    .map(([key, value]) => {
      const { subroutes, ...restProps } = value;
      const filteredChildred = subroutes?.map(({ component, ...rest }) => rest);
      const props = { ...restProps, subroutes: filteredChildred };
      return [key, props];
    }),
);

export default routes;
