import { createAction, generateScopedActionCreator } from '@synthetica-ai/system';

const { createEffectAction } = generateScopedActionCreator('APP');

export const saveUuid = createEffectAction('SAVE_UUID');
export const startApplication = createEffectAction('START_APPLICATION');
export const getVessels = createEffectAction('GET_VESSELS');
export const getDrydocks = createEffectAction('GET_DRYDOCKS');
export const initiateDrydock = createEffectAction('INITIATE_DRYDOCK');
export const redirect = createEffectAction('REDIRECT');
export const updateRoute = createEffectAction('UPDATE_ROUTE');
export const setAdminEditRights = createAction('SET_ADMIN_EDIT_RIGHTS');
export const exportCsv = createEffectAction('EXPORT_CSV');
export const exportSummary = createEffectAction('EXPORT_SUMMARY');
export const exportTimeplan = createEffectAction('EXPORT_TIMEPLAN');
export const downloadTutorial = createEffectAction('DOWNLOAD_TUTORIAL');
