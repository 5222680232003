export const INITIATE_DRYDOCK = 'INITIATE_DRYDOCK';
export const DRYDOCK_LIST = 'DRYDOCK_LIST';
export const DELETE_JOB = 'DELETE_JOB';
export const ADD_JOB = 'ADD_JOB';
export const OPEN_CAMERA = 'OPEN_CAMERA';
export const IMPORT_CSV = 'IMPORT_CSV';
export const SHIPYARD_PRICES = 'SHIPYARD_PRICES';
export const ADD_OTHER_COST = 'ADD_OTHER_COST';
export const EXPORT_CSV = 'EXPORT_CSV';
export const EXPORT_OTHER_COSTS_CSV = 'EXPORT_OTHER_COSTS_CSV';
export const DATE_PICKER = 'DATE_PICKER';
export const FINALIZE = 'FINALIZE';
export const TIMEPLAN = 'TIMEPLAN';
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const NOT_IMPORTED_JOBS = 'NOT_IMPORTED_JOBS';
