import { BASE_URL } from 'constants/origins';
import { createEndpoint, withCompanyAndToken } from 'utils/services';

export const drydockDetails = withCompanyAndToken(({ payload = '' }) => ({
  url: createEndpoint(BASE_URL, 'jobs', payload),
  method: 'GET',
}));

export const jobsService = withCompanyAndToken((payload) => ({
  url: createEndpoint(BASE_URL, 'jobs'),
  method: 'PUT',
  body: payload,
}));

export const addJob = withCompanyAndToken(([uuid, payload]) => ({
  url: createEndpoint(BASE_URL, 'drydock', uuid),
  method: 'POST',
  body: payload,
}));

export const uploadImages = withCompanyAndToken(([id, body]) => ({
  url: createEndpoint(BASE_URL, 'files', id),
  method: 'POST',
  body,
}));

export const deleteImage = withCompanyAndToken(({ payload }) => ({
  url: createEndpoint(BASE_URL, 'files', payload),
  method: 'DELETE',
}));

export const getJobImages = withCompanyAndToken(({ payload }) => ({
  url: createEndpoint(BASE_URL, 'files', payload),
  method: 'GET',
}));

export const updateProjectDetails = withCompanyAndToken(([uuid, data]) => ({
  url: createEndpoint(BASE_URL, 'drydock', uuid),
  method: 'PATCH',
  body: data,
}));

export const getShipyardFiles = withCompanyAndToken(({ payload }) => ({
  url: createEndpoint(BASE_URL, 'project-files', payload),
  method: 'GET',
}));

export const deleteFile = withCompanyAndToken(({ payload }) => ({
  url: createEndpoint(BASE_URL, 'project-files', payload),
  method: 'DELETE',
}));

export const downloadFile = withCompanyAndToken(([payload, uuid]) => ({
  url: createEndpoint(BASE_URL, 'project-files', uuid),
  method: 'PATCH',
  body: {
    file_id: payload,
  },
}));

export const uploadShipyardFiles = withCompanyAndToken(([uuid, payload, type]) => ({
  url: createEndpoint(BASE_URL, 'project-files', uuid),
  method: 'POST',
  body: [
    {
      file: payload.file,
      filename: payload.filename,
      type,
    },
  ],
}));

export const getDDTimes = withCompanyAndToken(({ payload: uuid }) => ({
  url: createEndpoint(BASE_URL, 'dd-times', uuid),
  method: 'GET',
}));

export const updateDDTimes = withCompanyAndToken(([data, uuid]) => ({
  url: createEndpoint(BASE_URL, 'dd-times', uuid),
  method: 'PUT',
  body: data,
}));

export const uploadTimeplanFile = withCompanyAndToken(([data, uuid]) => ({
  url: createEndpoint(BASE_URL, 'timeplan-files', uuid),
  method: 'POST',
  body: data,
}));

export const downloadTimeplanFile = withCompanyAndToken(([file_id, uuid]) => ({
  url: createEndpoint(BASE_URL, 'timeplan-files', uuid),
  method: 'PATCH',
  body: { file_id },
}));

export const deleteTimeplanFile = withCompanyAndToken(({ payload: id }) => ({
  url: createEndpoint(BASE_URL, 'timeplan-files', id),
  method: 'DELETE',
  // body: { file_id },
}));

export const finalizeDD = withCompanyAndToken((uuid) => ({
  url: createEndpoint(BASE_URL, 'archive', uuid),
  method: 'PATCH',
}));

export const getHistoryJobs = withCompanyAndToken((uuid) => ({
  url: createEndpoint(BASE_URL, 'history_jobs', uuid),
  method: 'GET',
}));

export const revertHistory = withCompanyAndToken(([payload, uuid]) => ({
  url: createEndpoint(BASE_URL, 'history_jobs', uuid),
  body: { datetime_changed: payload },
  method: 'POST',
}));
