import { generateScopedActionCreator } from '@synthetica-ai/system';

const { createEffectAction } = generateScopedActionCreator('DRYDOCK');

export const getDrydock = createEffectAction('GET_DRYDOCK');
export const setEditRights = createEffectAction('EDIT_CATEGORY_B');
export const updateJob = createEffectAction('UPDATE_JOB');
export const updateDrydock = createEffectAction('UPDATE_DRYDOCK');
export const deleteJob = createEffectAction('DELETE_JOB');
export const finalizeDrydock = createEffectAction('FINALIZE_DRYDOCK');
export const addJob = createEffectAction('ADD_JOB');
export const uploadCsv = createEffectAction('UPLOAD_CSV');
export const updateDiscount = createEffectAction('UPDATE_DISCOUNT');
export const getPaintAreas = createEffectAction('GET_PAINT_AREAS');
export const updateShipyardPrice = createEffectAction('UPDATE_SHIPYARD_PRICE');

export const getOtherCostCategories = createEffectAction('GET_OTHER_COST_CATEGORIES');
export const getOtherCosts = createEffectAction('GET_OTHER_COSTS');
export const updateOtherCosts = createEffectAction('UPDATE_OTHER_COSTS');
export const setCost = createEffectAction('UPDATE_COST');
export const deleteOtherCost = createEffectAction('DELETE_OTHER_COST');
export const updateExpandedRows = createEffectAction('UPDATE_EXPANDED_ROWS');
export const exportOtherCostsCsv = createEffectAction('EXPORT_OTHER_COSTS_CSV');
