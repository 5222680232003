import { useState } from 'react';
import { Button, DialogActions, TextField } from '@mui/material';
import { Flex } from '@synthetica-ai/components';
import { DateTimePicker } from '@mui/lab';

const DatePicker = ({
  closeDialog,
  props,
}) => {
  const [date, setDate] = useState(new Date());

  const handleChange = (value) => {
    setDate(value);
  };

  return (
    <Flex
      center
      gap="2rem"
      direction="column"
      width="100%"
    >
      <DateTimePicker
        label="Choose Date"
        ampm={false}
        inputFormat="ddMMMyy hh:mm a"
        value={date}
        onChange={handleChange}
        renderInput={(params) => <TextField size="small" {...params} />}
        {...props.dateProps}
      />
      <DialogActions sx={{ ml: 'auto', mt: '1rem' }}>
        <Button
          color="primary"
          variant="outlined"
          onClick={closeDialog}
        >
          Cancel
        </Button>

        <Button
          color="primary"
          variant="contained"
          disabled={!date}
          onClick={() => {
            closeDialog();
            const time = date.toLocaleTimeString('en-US', { hour12: false });
            props.onClick(date, time);
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Flex>
  );
};

export default DatePicker;
