import { LoadingButton } from '@mui/lab';
import { DialogActions } from '@mui/material';
import { withProps } from '@synthetica-ai/system';
import { exportCsv, loadingState } from 'models/app';

const ExportCsv = ({ exportCsv, loadingState }) => (
  <div style={{ textAlign: 'center' }}>
    Export
    <DialogActions sx={{ ml: 'auto', mt: '1rem' }}>
      <LoadingButton
        loading={loadingState.prices}
        color="primary"
        variant="outlined"
        onClick={() => exportCsv('prices')}
      >
        With Prices
      </LoadingButton>
      <LoadingButton
        loading={loadingState.prices}
        color="primary"
        variant="contained"
        onClick={() => exportCsv()}
      >
        No Prices
      </LoadingButton>
    </DialogActions>
  </div>
);

export default withProps({ exportCsv, loadingState })(ExportCsv);
