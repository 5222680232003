import { createReducer, createEntityAdapter, current } from '@reduxjs/toolkit';

import * as actions from './actions';

export const adapter = createEntityAdapter();

const initState = {
  canEdit: null,
  paintAreas: {},
  defaultJobs: adapter.getInitialState(),
  jobs: adapter.getInitialState(),
  otherCosts: adapter.getInitialState(),
  categories: [],
  expandedRows: [],
  changes: {},
  otherCostsChanges: {},
  isLoading: false,
};
const selectors = adapter.getSelectors((jobs) => jobs);

const calculatePrice = (path, value, job) => {
  const getProp = (type) => job?.[type] || '';

  const state = {
    quantity: getProp('quantity'),
    gross_unit_price: getProp('gross_unit_price'),
    percentage: getProp('percentage'),
    discount: getProp('discount'),
    net_unit_price: getProp('net_unit_price'),
    total_net_item_price: getProp('total_net_item_price'),
  };

  if (path == 'total_net_item_price' && state.total_net_item_price !== value) {
    state.gross_unit_price = '';
    state.percentage = '';
    state.discount = '';
    state.net_unit_price = value;
    state.quantity = 1;
    state.total_net_item_price = value;
    return state;
  }

  if (path == 'net_unit_price'  && state.net_unit_price !== value) {
    state.total_net_item_price = (value * state.quantity).toFixed(0);
    state.gross_unit_price = '';
    state.net_unit_price = value;
    return state;
  }

  state[path] = value;

  if (state.gross_unit_price) {
    state.net_unit_price = (state.gross_unit_price * ((100 - state.discount) / 100)).toFixed(2);
  }
  if (state.net_unit_price && state.quantity) {
    state.total_net_item_price = (state.net_unit_price * state.quantity).toFixed(0);
  }
  return state;
};

const reducer = createReducer(initState, (builder) => builder
  .addCase(actions.getDrydock, (state) => ({
    ...initState,
    categories: state.categories,
    otherCosts: state.otherCosts,
  }))
  .addCase(actions.getDrydock.success, (state, { payload }) => {
    const data = payload.map((datum) => ({
      ...datum,
      quantity: Math.ceil(datum.quantity),
    }));
    adapter.upsertMany(state.defaultJobs, data);
  })
  .addCase(actions.getPaintAreas.success, (state, { payload }) => {
    state.paintAreas = payload.areas;
  })
  .addCase(actions.setEditRights, (state, { payload }) => {
    state.canEdit = payload;
  })
  .addCase(actions.updateJob, (state, { payload }) => {
    let data = payload.values;
    if (payload.path) {
      const job = selectors.selectById(state.jobs, payload.id);
      const defaultJob = selectors.selectById(state.defaultJobs, payload.id);
      data = calculatePrice(payload.path, payload.value, job || defaultJob);
    }
    adapter.upsertOne(state.jobs, { id: payload.id, ...data });
    state.changes = {
      ...state.changes,
      [payload.id]: { ...state.changes[payload.id], ...payload.values },
    };
  })
  .addCase(actions.updateDrydock.success, (state, { payload }) => {
    state.canEdit = null;
    adapter.upsertMany(state.defaultJobs, payload);
    adapter.removeAll(state.jobs);
    state.changes = {};
  })
  .addCase(actions.deleteJob.success, (state, { payload }) => {
    adapter.removeMany(state.defaultJobs, payload);
  })
  .addCase(actions.addJob.success, (state, { payload }) => {
    adapter.addOne(state.defaultJobs, payload);
  })
  .addCase(actions.getOtherCostCategories.success, (state, { payload }) => {
    state.categories = payload;
    adapter.upsertMany(state.otherCosts, payload.map((datum) => ({
      id: -datum.id,
      category_id: datum.id,
      label: datum.name,
    })));
  })
  .addCase(actions.getOtherCosts, (state) => {
    const { ids } = current(state.otherCosts);
    adapter.removeMany(state.otherCosts, ids.filter((id) => id > 0));
  })
  .addCase(actions.getOtherCosts.success, (state, { payload }) => {
    adapter.upsertMany(state.otherCosts, payload);
  })
  .addCase(actions.setCost, (state, { payload }) => {
    adapter.upsertOne(state.otherCosts, { id: payload.id, ...payload });
    state.otherCostsChanges = {
      ...state.otherCostsChanges,
      [payload.id]: { ...state.otherCostsChanges[payload.id], ...payload },
    };
  })
  .addCase(actions.updateOtherCosts.success, (state, { payload }) => {
    const snapshot = current(state.otherCosts).entities;
    const data = Object
      .values(snapshot)
      .map((datum) => ({ ...datum, isUpdated: false }));

    adapter.upsertMany(state.otherCosts, data);
    adapter.upsertMany(state.otherCosts, payload);
    state.otherCostsChanges = {};
  })
  .addCase(actions.deleteOtherCost.success, (state, { payload }) => {
    adapter.removeMany(state.otherCosts, payload);
  })
  .addCase(actions.uploadCsv, (state) => {
    state.isLoading = true;
  })
  .addCase(actions.uploadCsv.success, (state) => {
    state.isLoading = false;
  })
  .addCase(actions.uploadCsv.fail, (state) => {
    state.isLoading = false;
  })
  .addCase(actions.updateExpandedRows, (state, { payload }) => {
    if (state.expandedRows.includes(payload)) {
      state.expandedRows = state.expandedRows.filter((id) => id != payload);
      return;
    }

    state.expandedRows.push(payload);
  }));

export default reducer;
