import { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { DatePicker, LoadingButton } from '@mui/lab';
import { Flex } from '@synthetica-ai/components';

import {
  Button,
  DialogActions,
  TextField,
} from '@mui/material';

import { VerticalStepper } from 'components';
import { withProps } from '@synthetica-ai/system';
import { initiateDrydock, isLoading } from 'models/app';

import {
  VesselInput,
  Overview,
} from './components';

const InitiateDrydock = ({
  closeDialog,
  initiateDrydock,
  history,
  isLoading,
}) => {
  const [activeStep, setActiveStep] = useState(0);

  const [state, setState] = useState({
    date: new Date(),
    vessel: null,
    shipyard: '',
    super_intendent: '',
    dd_creator: '',
  });

  const steps = [
    {
      label: 'Drydock Date',
      disabled: !state.date,
      children: <DatePicker
        views={['year', 'month']}
        inputFormat="MMMyy"
        value={state.date}
        onChange={(date) => setState((prev) => ({ ...prev, date }))}
        renderInput={(params) => <TextField {...params} size="small" helperText={null} />}
      />,
    },
    {
      label: 'Select Vessel',
      disabled: !state.vessel,
      children: <VesselInput
        value={state.vessel}
        onChange={(vessel) => setState((prev) => ({ ...prev, vessel }))}
      />,
    },
    // {
    //   label: 'Select Shipyard',
    //   disabled: !state.shipyard,
    //   children: <TextField
    //     value={state.shipyard}
    //     autoFocus
    //     onChange={(ev) => setState((prev) => ({ ...prev, shipyard: ev.target.value }))}
    //     size="small"
    //     variant="standard"
    //   />,
    // },
    {
      label: 'Superintendent',
      disabled: !state.super_intendent,
      children: <TextField
        value={state.super_intendent}
        autoFocus
        onChange={(ev) => setState((prev) => ({ ...prev, super_intendent: ev.target.value }))}
        size="small"
        variant="standard"
      />,
    },
    {
      label: 'DD Creator',
      disabled: !state.dd_creator,
      children: <TextField
        value={state.dd_creator}
        autoFocus
        onChange={(ev) => setState((prev) => ({ ...prev, dd_creator: ev.target.value }))}
        size="small"
        variant="standard"
      />,
    },
  ];

  return (
    <Flex center direction="column" style={{ width: '100%' }}>
      <VerticalStepper
        steps={steps}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        overviewElement={<Overview state={state} />}
      />
      <DialogActions sx={{ ml: 'auto', mt: '1rem' }}>
        <Button
          color="primary"
          variant="outlined"
          onClick={closeDialog}
        >
          Cancel
        </Button>

        <LoadingButton
          loading={isLoading}
          color="primary"
          variant="contained"
          onClick={() => initiateDrydock({
            data: state,
            push: history.push,
          })}
          disabled={activeStep < steps.length}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Flex>
  );
};

export default withRouter(withProps({
  initiateDrydock,
  isLoading,
})(InitiateDrydock));
