import { createSelector } from '@reduxjs/toolkit';
import {
  adapter,
} from './reducer';

const adapterSelector = (prop) => createSelector(
  ({ drydock }) => drydock[prop],
  (datum) => adapter.getSelectors().selectAll(datum),
);

export const jobs = adapterSelector('jobs');
export const defaultJobs = adapterSelector('defaultJobs');
export const otherCosts = adapterSelector('otherCosts');
export const canEdit = ({ drydock }) => drydock.canEdit;
export const paintAreas = ({ drydock }) => drydock.paintAreas;
export const categories = ({ drydock }) => drydock.categories;
export const expandedRows = ({ drydock }) => drydock.expandedRows;
export const changes = ({ drydock }) => drydock.changes;
export const otherCostsChanges = ({ drydock }) => drydock.otherCostsChanges;
export const isLoading = ({ drydock }) => drydock?.isLoading;
