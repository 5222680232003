import { reducer as ui } from './ui';
import { reducer as app, epics as appEpics } from './app';
import { reducer as user, epics as userEpics } from './user';
import { reducer as worklist, epics as worklistEpics } from './worklist';

export const models = {
  reducers: {
    ui,
    app,
    user,
    worklist,
  },
  epics: {
    appEpics,
    userEpics,
    worklistEpics,
  },
};
