import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  tableContainer: {
    borderBottom: '0',
    height: '75vh',
    backgroundColor: 'white',
    border: '1px solid #012842',
    // borderTop: 0,
    borderRadius: '8px',
    overflowX: 'auto',
    '& > table': {
      borderBottom: '1px solid #012842',
      borderBottomRightRadius: '8px',
      borderBottomLeftRadius: '8px',
    },
  },
  pagination: {
    position: 'sticky',
    bottom: 0,
    // borderTop: '1px solid #012842',
    backgroundColor: 'white',
    borderTopRightRadius: '8px',
    borderTopLeftRadius: '8px',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '1.5rem',
  },
}));
