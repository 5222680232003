export const typography = {
  h1: {
    fontSize: '4rem',
    lineHeight: '0.875em',
    fontFamily: 'Red Hat Text',
    fontWeight: 500,
  },
  h2: {
    fontSize: '3rem',
    lineHeight: '1em',
    fontFamily: 'Red Hat Text',
    fontWeight: 400,
  },
  h3: {
    fontSize: '2.375rem',
    lineHeight: '1.525em',
    fontFamily: 'Red Hat Text',
    fontWeight: 400,
  },
  h4: {
    fontSize: '2rem',
    lineHeight: '1.2em',
    fontFamily: 'Red Hat Text',
    fontWeight: 400,
  },
  h5: {
    fontSize: '1.125rem',
    lineHeight: '1em',
    fontFamily: 'Red Hat Text',
    fontWeight: 400,
  },
  body1: {
    fontSize: '1.25rem',
    lineHeight: '1.17em',
    fontFamily: 'Red Hat Text',
    fontWeight: 400,
  },
  body2: {
    fontSize: '1.125rem',
    lineHeight: '1.34em',
    fontFamily: 'Red Hat Text',
    fontWeight: 400,
  },
  subtitle1: {
    fontSize: '1.5rem',
    lineHeight: '1.17em',
    fontFamily: 'Red Hat Text',
    fontWeight: 500,
  },
  subtitle2: {
    fontSize: '1.5rem',
    lineHeight: '1.34em',
    fontFamily: 'Red Hat Text',
    fontWeight: 300,
  },
};
