import { Flex } from '@synthetica-ai/components';
import { withProps } from '@synthetica-ai/system';
import { drydocks } from 'models/app';
import { user } from 'models/user';
import { useEffect, useState } from 'react';

import { Filters, List } from './components';

const DrydockList = ({ drydocks, user }) => {
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({
    vessel: null,
    fleet: null,
  });

  useEffect(() => {
    setData(drydocks
      ?.filter(({ finalized }) => !finalized)
      ?.filter((datum) => filters.vessel == null || datum.vessel.name === filters.vessel)
      ?.filter((datum) => filters.fleet == null || datum.vessel.fleet === filters.fleet)
      ?.filter((datum) => user.drydock_ids == null || user?.drydock_ids.includes(datum.uuid)));
  }, [drydocks, filters]);

  return (
    <Flex
      gap="2rem"
      padding="1rem"
      direction="column"
      width="100%"
    >
      <Filters
        data={data}
        state={filters}
        setState={setFilters}
      />
      <List data={data} />
    </Flex>
  );
};

export default withProps({
  drydocks,
  user,
})(DrydockList);
