import { Button, DialogActions } from '@mui/material';
import { withProps } from '@synthetica-ai/system';
import { exportOtherCostsCsv } from 'models/drydock';

const ExportOtherCostsCsv = ({ exportOtherCostsCsv }) => (
  <div style={{ textAlign: 'center' }}>
    Export
    <DialogActions sx={{ ml: 'auto', mt: '1rem' }}>
      <Button
        color="primary"
        variant="contained"
        onClick={() => exportOtherCostsCsv('report')}
      >
        Per Account Number
      </Button>
      <Button
        color="primary"
        variant="contained"
        onClick={() => exportOtherCostsCsv()}
      >
        Per Category
      </Button>
    </DialogActions>
  </div>
);

export default withProps({ exportOtherCostsCsv })(ExportOtherCostsCsv);
