export const TEXTFIELD = 'TEXTFIELD';
export const AUTOCOMPLETE = 'AUTOCOMPLETE';

export const fields = [
  {
    label: 'Category', path: 'category_id', field: AUTOCOMPLETE,
  },
  { label: 'Job Description', path: 'label', field: TEXTFIELD },
  { label: 'Case ID', path: 'case_id', field: TEXTFIELD },
  { label: 'Contractor', path: 'supplier', field: TEXTFIELD },
  {
    label: 'Estimated Cost',
    path: 'estimated_cost',
    field: TEXTFIELD,
    inputProps: { type: 'number', inputMode: 'numeric', pattern: '[0-9]*' },
  },
  { label: 'Remarks', path: 'remarks', field: TEXTFIELD },
];
