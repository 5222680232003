import { forwardRef } from 'react';
import NumberFormat from 'react-number-format';

const validation = (value, isNegative) => {
  const decimals = value?.toString().split('.')[1]?.length || 0;
  return isNegative ? decimals <= 2 : (value >= 0 && decimals <= 2);
};

const NumberFormatCustom = forwardRef((props, ref) => {
  const {
    onChange,
    validate = validation,
    isNegative,
    ...other
  } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      isAllowed={(values) =>
        values.floatValue == undefined || validate(values.floatValue, isNegative)}
      isNumericString
      thousandSeparator
      onValueChange={(values, source) => {
        source.event && onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
    />
  );
});

export default NumberFormatCustom;
