import { memo, useMemo } from 'react';
import {
  useTable, useSortBy, usePagination, useExpanded,
} from 'react-table';
import {
  TablePagination, TableContainer, Table as MaterialTable, Typography,
} from '@mui/material';
import clsx from 'clsx';

import { Flex } from '@synthetica-ai/components';
import { TableHead, TableBody, DefaultCell } from './components';
import { useStyles } from './styles';

const Table = ({
  id,
  columns,
  data,
  updateData,
  style,
  className,
  skipPageReset,
  defaultPageSize,
  rowsPerPageOptions,
  autoResetExpanded,
  hasPagination,
  defaultCell,
  label,
  expanded = {},
}) => {
  const classes = useStyles();

  const defaultColumn = useMemo(
    () => ({
      Cell: defaultCell || DefaultCell,
    }),
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      updateData,
      autoResetPage: !skipPageReset,
      autoResetExpanded,
      initialState: {
        pageSize: defaultPageSize,
        expanded,
      },
    },
    useSortBy,
    useExpanded,
    usePagination,
  );

  return (
    <TableContainer
      style={style}
      className={clsx(classes.tableContainer, className)}
    >
      {(label || hasPagination) && (
        <Flex className={classes.pagination}>
          <Typography fontWeight={400}>
            {label}
          </Typography>
          {hasPagination && (
            <TablePagination
              component="div"
              page={pageIndex}
              rowsPerPage={pageSize}
              count={rows.length}
              rowsPerPageOptions={rowsPerPageOptions}
              onPageChange={(_, newPage) => gotoPage(newPage)}
              onRowsPerPageChange={(ev) => setPageSize(ev.target.value)}
            />
          )}
        </Flex>
      )}
      <MaterialTable id={id} stickyHeader {...getTableProps()}>
        <TableHead headerGroups={headerGroups} />
        <TableBody
          rows={hasPagination ? page : rows}
          prepareRow={prepareRow}
          getTableBodyProps={getTableBodyProps}
        />
      </MaterialTable>
    </TableContainer>
  );
};

export default memo(Table);
