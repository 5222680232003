import {
  TableCell,
  TableRow,
  TableBody as MaterialTableBody,
} from '@mui/material';

import { useStyles } from './styles';

const getColor = (index) => ({
  0: '',
  2: '#e5e5e5',
  1: '#d3d3d3',
})[index];

const TableBody = ({ getTableBodyProps, rows, prepareRow }) => {
  const classes = useStyles();
  return (
    <MaterialTableBody {...getTableBodyProps()}>
      {rows.map((row) => {
        prepareRow(row);
        const key = `${row.getRowProps().key}-${row.original.id || ''}`;
        return (
          <TableRow {...row.getRowProps()} key={key} className={row.depth === 0 ? classes.row : ''}>
            {row.cells.map((cell) => {
              const style = cell.column.cellStyle?.(row) || {};
              return (
                <TableCell
                  {...cell.getCellProps()}
                  className={row.depth === 0 ? classes.cell : classes.cell2}
                  style={{ backgroundColor: getColor(row.depth), ...style }}
                >
                  {cell.render('Cell')}
                </TableCell>
              );
            })}
          </TableRow>
        );
      })}
    </MaterialTableBody>
  );
};

export default TableBody;
