import { compose } from 'redux';

import { TOKEN, USER } from '@synthetica-ai/constants';
import { localStorageValue } from '@synthetica-ai/utils/storage';

export const withToken = (service) => (payload) => {
  const jwt = localStorageValue(TOKEN).get();
  const options = service(payload);

  return {
    ...options,
    headers: {
      ...options.headers,
      authorization: `Bearer ${jwt}`,
    },
  };
};

export const withCompany = (service) => (payload) => {
  const company = localStorageValue(USER).get()?.company;
  const options = service(payload);
  const { url } = options;
  const elements = url.split('/');
  elements.splice(4, 0, company);
  const newUrl = elements.join('/');
  return {
    ...options,
    url: newUrl,
  };
};

export const withCompanyAndToken = (service) => compose(
  withToken,
  withCompany,
)(service);

export const createEndpoint = (host, ...strings) => strings
  .filter(Boolean)
  .reduce((acc, cur) => `${acc}/${cur}`, host);
