const waitingText = 'A new service worker has installed, but it can\'t activate until all tabs running the current version have fully unloaded.';
const activatedText = 'Service worker activated for the first time!';

const registerServiceWorker = async () => {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }

  if ('serviceWorker' in navigator) {
    console.log('inside service worker');
    const { Workbox } = await import('workbox-window');
    const wb = new Workbox('/service-worker.js');

    wb.addEventListener('waiting', () => {
      console.log(waitingText);
    });

    wb.addEventListener('activated', (event) => {
      if (!event.isUpdate) {
        console.log(activatedText);
      }
    });

    wb.addEventListener('installed', (event) => {
      if (event.isUpdate) {
        if (confirm('New app update is available!. Click OK to refresh')) {
          window.location.reload();
        }
      }
    });

    wb.register();
  }
};

export default registerServiceWorker;
