import { Typography } from '@mui/material';
import { format } from 'date-fns';
import { Flex } from '@synthetica-ai/components';
import { startCase } from 'lodash';

const Overview = ({ state }) => {
  const details = [
    { label: 'Date', value: format(state.date, 'MMMyy') },
    { label: 'Vessel', value: state.vessel.name },
    { label: 'Superintendent', value: state.super_intendent },
    { label: 'DD Creator', value: state.dd_creator },
  ];

  return (
    <div>
      <Typography gutterBottom variant="h4">
        Overview
      </Typography>
      {details.map((datum, key) => (
        <Flex key={key} gap="0.5rem">
          <Typography fontWeight={500}>
            {datum.label}
            :
          </Typography>
          <Typography>
            {startCase(datum.value)}
          </Typography>
        </Flex>
      ))}
    </div>
  );
};

export default Overview;
