import { Autocomplete, TextField } from '@mui/material';
import { Flex } from '@synthetica-ai/components';
import { startCase, uniq } from 'lodash';

const Fitlers = ({ data, state, setState }) => (
  <Flex
    gap="1rem"
    width="100%"
    sx={{
      '& > div': {
        flex: 1,
      },
    }}
  >
    <Autocomplete
      size="small"
      value={state.fleet}
      getOptionLabel={(option) => startCase(option)}
      options={uniq(data.map(({ vessel }) => vessel.fleet))}
      onChange={(_, fleet) => setState((prev) => ({ ...prev, fleet }))}
      renderInput={(params) => <TextField {...params} label="Fleet" />}
    />
    <Autocomplete
      size="small"
      value={state.vessel}
      getOptionLabel={(option) => startCase(option)}
      options={uniq(data.map(({ vessel }) => vessel.name))}
      onChange={(_, vessel) => setState((prev) => ({ ...prev, vessel }))}
      renderInput={(params) => <TextField {...params} label="Vessel" />}
    />
  </Flex>
);

export default Fitlers;
