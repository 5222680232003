import { BASE_URL } from 'constants/origins';
import { createEndpoint, withCompanyAndToken } from 'utils/services';

export const vessels = withCompanyAndToken(() => ({
  url: createEndpoint(BASE_URL, 'vessels'),
  method: 'GET',
}));

export const drydock = withCompanyAndToken(() => ({
  url: createEndpoint(BASE_URL, 'drydock'),
  method: 'GET',
}));

export const initiateDrydock = withCompanyAndToken((body) => ({
  url: createEndpoint(BASE_URL, 'initiate'),
  body,
  method: 'POST',
}));

export const exportCsv = withCompanyAndToken(([uuid, prices]) => ({
  url: createEndpoint(BASE_URL, 'export', uuid, prices),
  method: 'GET',
}));

export const exportSummary = withCompanyAndToken((uuid) => ({
  url: createEndpoint(BASE_URL, 'summary-export', uuid, 'budget'),
  method: 'GET',
}));

export const exportTimeplan = withCompanyAndToken((uuid) => ({
  url: createEndpoint(BASE_URL, 'timeplan-export', uuid),
  method: 'GET',
}));

export const downloadTutorial = withCompanyAndToken(() => ({
  url: createEndpoint(BASE_URL, 'project-files', 1),
  method: 'PATCH',
  body: {
    file_id: '64086518f72a25ee615771f3',
  },
}));
