import { Box, Typography, Divider } from '@mui/material';
import { Flex } from '@synthetica-ai/components';

const BoxWithTitle = ({
  children,
  label,
  headerComponent,
  style,
}) => (
  <Box sx={{
    border: '1px solid',
    borderRadius: '8px',
    ...style,
  }}
  >
    <Flex justifyContent="space-between" alignItems="center" padding="1rem">
      <Typography>
        {label}
      </Typography>

      {headerComponent}
    </Flex>
    <Divider sx={{ bgcolor: 'black' }} />

    {children}
  </Box>
);

export default BoxWithTitle;
