import { useState, useEffect } from 'react';
import { Delete } from '@mui/icons-material';
import { Flex } from '@synthetica-ai/components';
import { withProps } from '@synthetica-ai/system';
import { startCase } from 'lodash';

const ImageGallery = ({
  onClick,
  images,
  deleteItem,
  hasEditRights,
  transform = (value) => value.filename,
}) => {
  const [state, setState] = useState(images);

  useEffect(() => {
    setState(images);
  }, [images]);

  const handleClick = (filename, file, id) => {
    if (onClick) {
      console.log(id);
      onClick(id);
      return;
    }
    const imageLink = document.getElementById('image-link');
    imageLink.href = file;
    imageLink.download = filename;
    imageLink.click();
  };

  return (
    <Flex direction="column" paddingTop="1rem" gap="1rem">
      {state.map((image, index) => (
        <div key={index} style={{ position: 'relative', display: 'flex' }}>
          {
            image?.file?.split('/')[0] === 'data:image'
              ? (
                <img
                  onClick={() => handleClick(image.filename, image.file, image.id)}
                  src={image.file}
                  alt={image.filename}
                  style={{ width: '80px', height: '80px', cursor: 'pointer' }}
                />
              )
              : (
                <span
                  style={{ cursor: 'pointer', fontSize: '12px' }}
                  onClick={() => handleClick(image.filename, image.file, image.id)}
                >
                  {transform(image)}
                </span>
              )
          }
          {hasEditRights && (
            <Delete
              onClick={() => {
                deleteItem(image.id);
                setState((prev) => prev.filter(({ id }) => id != image.id));
              }}
              style={{
                position: 'relative', bottom: '0', right: '-26px', cursor: 'pointer',
              }}
            />
          )}

        </div>

      ))}
    </Flex>
  );
};

export default ImageGallery;
