import { createTheme } from '@mui/material/styles';
import { palette } from './palette';
import { typography } from './typography';

export const theme = createTheme({
  palette,
  typography,
  child: (value) => `& [class*="${value}"]`,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'Red Hat Text',
          borderRadius: '8px',
          boxShadow: 'unset',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          borderRadius: '8px',
        },
        endAdornment: {
          '& svg': {
            fill: palette.secondary.main,
          },
        },
      },
    },
  },
});
