import { useState, useEffect } from 'react';
import { Cancel } from '@mui/icons-material';

import { Flex } from '@synthetica-ai/components';
import { fileListToBase64 } from 'utils/file';
import { closeDialog, openDialog } from 'models/ui';
import { withProps } from '@synthetica-ai/system';
import { OPEN_CAMERA } from 'constants/dialog';

const ImageUpload = ({
  onUpload,
  openDialog,
  closeDialog,
}) => {
  const [images, setImages] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);

  useEffect(() => {
    const newImageUrls = [];
    images.forEach((image) => newImageUrls.push({
      url: URL.createObjectURL(image),
      name: image.name,
    }));
    setImageUrls(newImageUrls);
  }, [images]);

  const onImageChange = (e) => {
    setImages((prev) => [...prev, e.target.files[0]]);
  };

  const removeImage = (imageName) => {
    const filteredImages = images.filter((image) => image.name !== imageName);
    setImages(filteredImages);
  };

  const uploadFile = async () => {
    const arrayOfBase64 = await fileListToBase64(images);
    const files = arrayOfBase64.map((file, index) => ({
      file,
      filename: images[index].name,
    }));
    onUpload(files);
  };

  return (
    <Flex direction="column" gap="2rem">
      <Flex gap="0.5rem" marginLeft="auto">
        <input
          style={{ width: '90px' }}
          type="file"
          onChange={onImageChange}
        />
        <button onClick={() => {
          openDialog({
            type: OPEN_CAMERA,
            fullScreen: true,
            props: {
              onClick: (imageSrc) => {
                fetch(imageSrc)
                  .then((res) => res.blob())
                  .then((blob) => {
                    const file = new File([blob], 'custom.png', { type: 'image/png' });
                    setImages((prev) => [...prev, file]);
                    closeDialog();
                  })
                  .catch(() => console.log('err'));
              },
            },
          });
        }}
        >
          Take Photo
        </button>
      </Flex>
      <button onClick={() => {
        uploadFile();
        setImages([]);
      }}
      >
        Upload
      </button>
      <Flex gap="2rem" padding="1rem">
        {imageUrls.map((imageSrc, index) => (
          <div
            key={index}
            style={{ position: 'relative' }}
          >
            <Cancel
              onClick={() => removeImage(imageSrc.name)}
              style={{
                cursor: 'pointer',
                position: 'absolute',
                top: '-10px',
                right: '-10px',
                width: ' 20px',
                height: '20px',
              }}
            />
            {imageSrc.url.split('/')[0] === 'data:image'
              ? (
                <img
                  title=""
                  src={imageSrc.url}
                  style={{ width: '80px', height: '80px' }}
                  alt="u"
                />
              )
              : (
                <span
                  style={{ fontSize: '12px' }}
                >
                  {imageSrc.name}
                </span>
              )}
          </div>
        ))}
      </Flex>
    </Flex>
  );
};

export default withProps({
  openDialog,
  closeDialog,
})(ImageUpload);
