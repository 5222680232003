import { format } from 'date-fns';

export const getNextDays = (currentDate, nextDays = 1) => {
  const nextDay = new Date(constructDate(currentDate));
  const dates = Array(nextDays).fill(0).map(() => format(
    new Date(nextDay.setDate(nextDay.getDate() + 1)),
    'ddMMMyy',
  ));
  return dates;
};

export const getPreviousDays = (currentDate, nextDays = 1) => {
  const nextDay = new Date(constructDate(currentDate));
  const dates = Array(nextDays).fill(0).map(() => format(
    new Date(nextDay.setDate(nextDay.getDate() - 1)),
    'ddMMMyy',
  )).reverse();
  return dates;
};

export const constructDate = (date) => {
  try {
    const day = date.substring(0, 2);
    const month = date.substring(2, 5);
    const year = `20${date.substring(5)}`;

    if (day && month && year) {
      const date = new Date(`${day} ${month} ${year}`);
      if (date == 'Invalid Date') {
        return null;
      }
      return date;
    }
    return new Date(date);
  } catch (error) {
    return new Date(date);
  }
};

export const isBetweenDates = (date, from, to) => {
  const checkDate = new Date(date);
  const fromDate = new Date(from);
  const toDate = new Date(to);

  return checkDate.getTime() < toDate.getTime() && fromDate.getTime() < checkDate.getTime();
};

export const convertTime = (time) => {
  let [hours = '00', minutes = '00', seconds = '00'] = time.split(':');
  hours = hours == '24' ? '00' : hours;
  const off_time = [hours, minutes, seconds].join(':');
  return off_time;
};
