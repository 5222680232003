import { useState } from 'react';
import { withProps } from '@synthetica-ai/system';
import { Flex } from '@synthetica-ai/components';
import {
  TextField,
  Button,
  DialogActions,
  Autocomplete,
} from '@mui/material';

import { shipyards } from 'models/app';
import { updateShipyardPrice } from 'models/drydock';

const ShipyardPrices = ({
  closeDialog,
  updateShipyardPrice,
  shipyards,
}) => {
  const [shipyard, setShipyard] = useState(null);
  const [tariff, setTariff] = useState(null);
  const tariffOptions = shipyards.find(({ name }) => name == shipyard?.name)?.tariff;
  return (
    <div>
      <Flex gap="1rem">
        <Autocomplete
          size="small"
          value={shipyard}
          getOptionLabel={(option) => option.name}
          options={shipyards}
          onChange={(_, value) => setShipyard(value)}
          renderInput={(params) => <TextField {...params} label="Shipyard" style={{ width: '15ch' }} />}
        />
        {shipyard && tariffOptions && (
          <Autocomplete
            size="small"
            value={tariff}
            options={tariffOptions}
            onChange={(_, value) => setTariff(value)}
            renderInput={(params) => <TextField {...params} label="Tarrif" style={{ width: '15ch' }} />}
          />
        )}
      </Flex>
      <DialogActions sx={{ ml: 'auto', mt: '1rem' }}>
        <Button
          color="primary"
          variant="outlined"
          onClick={closeDialog}
        >
          Close
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={!shipyard}
          onClick={() => updateShipyardPrice({ shipyard: shipyard.name, tariff })}
        >
          Update
        </Button>

      </DialogActions>
    </div>

  );
};

export default withProps({ updateShipyardPrice, shipyards })(ShipyardPrices);
