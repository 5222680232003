import { createEntityAdapter, createReducer } from '@reduxjs/toolkit';
import { groupBy, mapValues } from 'lodash';
import * as actions from './actions';

export const adapter = createEntityAdapter();

const initState = {
  drydockDetails: null,
  jobs: adapter.getInitialState(),
  photos: adapter.getInitialState(),
  details: null,
  files: [],
  times: [],
  tableTimes: adapter.getInitialState(),
};

const reducer = createReducer(initState, (builder) => builder
  .addCase(actions.setDrydockDetails, (state, { payload }) => {
    state.details = payload;
    state.historyJobs = null;
  })
  .addCase(actions.getDrydocksDetails.success, (state, { payload }) => {
    const data = payload.map(({ total_net_item_price, ...rest }) => ({
      ...rest,
      total_net_item_price: Number(total_net_item_price?.toFixed(0) || 0),
    }));

    state.drydockDetails = {
      defaultJobs: data,
      jobs: mapValues(
        groupBy(data, (datum) => datum.category_a),
        (data) => groupBy(data, (datum) => datum.category_b),
        (data) => groupBy(data, (datum) => datum.category_c),
      ),
    };
  })
  .addCase(actions.updateJob, (state, { payload }) => {
    adapter.upsertOne(state.jobs, { id: payload.id, ...payload.values });
  })
  .addCase(actions.updateProjectDetails.success, (state, { payload }) => {
    state.details = { ...state.details, ...payload };
  })
  .addCase(actions.getJobImages.success, (state, { payload }) => {
    adapter.upsertOne(state.photos, { id: payload.id, photos: payload.photos });
  })
  .addCase(actions.addJob.success, (state, { payload }) => {
    const data = [...state.drydockDetails.defaultJobs, payload];

    state.drydockDetails = {
      defaultJobs: data,
      jobs: mapValues(
        groupBy(data, (datum) => datum.category_a),
        (data) => groupBy(data, (datum) => datum.category_b),
        (data) => groupBy(data, (datum) => datum.category_c),
      ),
    };
  })
  .addCase(actions.updateJobs.success, (state, { payload }) => {
    const data = state.drydockDetails.defaultJobs
      ?.map((el) => {
        const item = payload?.find(({ id }) => id == el.id);
        return {
          ...el,
          ...item,
        };
      });

    state.drydockDetails = {
      defaultJobs: data,
      jobs: mapValues(
        groupBy(data, (datum) => datum.category_a),
        (data) => groupBy(data, (datum) => datum.category_b),
        (data) => groupBy(data, (datum) => datum.category_c),
      ),
    };

    adapter.upsertMany(state.jobs, payload);
  })
  .addCase(actions.getShipyardFiles, (state) => {
    state.files = [];
  })
  .addCase(actions.getShipyardFiles.success, (state, { payload }) => {
    state.files = payload.files;
  })
  .addCase(actions.getDDTimes, (state) => {
    state.times = [];
  })
  .addCase(actions.getDDTimes.success, (state, { payload }) => {
    state.times = payload.times;
  })
  .addCase(actions.setTableTimes, (state, { payload }) => {
    Array.isArray(payload)
      ? adapter.upsertMany(state.tableTimes, payload)
      : adapter.upsertOne(state.tableTimes, payload);
  })
  .addCase(actions.uploadShipyardFiles.success, (state, { payload }) => {
    state.files.push(payload[0]);
  })
  .addCase(actions.uploadTimeplanFile.success, (state, { payload, sourcePayload }) => {
    const { datetime } = sourcePayload?.[0] || {};
    const index = state.times.findIndex((value) => value.datetime.includes(datetime));
    state.times[index].files = [...(state.times[index].files || []), ...payload.message];
  })
  .addCase(actions.finalizeDD.success, (state) => {
    state.details.finished = true;
  })
  .addCase(actions.updateDDTimes.success, (state, { payload }) => {
    adapter.removeAll(state.tableTimes);
    state.times = payload.times;
  })
  .addCase(actions.getHistoryJobs.success, (state, { payload }) => {
    state.historyJobs = payload;
  })
  .addCase(actions.revertHistory.success, (state, { payload }) => {
    state.updatedJobs = payload.jobs_updated;
  }));

export default reducer;
