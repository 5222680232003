import {
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
} from '@mui/material';

const VerticalStepper = ({
  steps,
  activeStep,
  setActiveStep,
  overviewElement,
}) => {
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep} orientation="vertical" sx={{ mb: '1rem' }}>
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel onClick={() => activeStep > index && setActiveStep(index)}>
              {step.label}
            </StepLabel>
            <StepContent>
              <Box>
                {step.children}
              </Box>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    disabled={step.disabled}
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === steps.length - 1 ? 'Finish' : 'Continue'}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        overviewElement
      )}
    </Box>
  );
};

export default VerticalStepper;
