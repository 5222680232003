import { useState } from 'react';
import { Popover, Button, Typography } from '@mui/material';
import { Flex } from '@synthetica-ai/components';

export default function BasicPopover({
  icon,
  options = [],
  label,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'left',
  },
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Button
        variant="outlined"
        color="secondary"
        size="large"
        endIcon={icon}
        onClick={handleClick}
      >
        {label}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
      >
        <Flex direction="column" gap="1rem">
          {options.map(({ label, onClick }) => (
            <Typography
              key={label}
              style={{ cursor: 'pointer', padding: '0.5rem' }}
              component="span"
              onClick={() => {
                handleClose();
                onClick();
              }}
            >
              {label}
            </Typography>
          ))}
        </Flex>
      </Popover>
    </div>
  );
}
