import { Flex } from '@synthetica-ai/components';
import {
  Button,
  DialogActions,
} from '@mui/material';

import Table from 'components/Table';
import { createColumns } from './table-config';
import { useStyles } from './styles';

const NotImportedJobs = ({
  closeDialog,
  props,
}) => {
  const columns = createColumns();
  const classes = useStyles();
  return (
    <Flex center direction="column" style={{ width: '100%' }}>
      <Table
        className={classes.tableContainer}
        columns={columns}
        data={props}
      />
      <DialogActions sx={{ ml: 'auto', mt: '1rem' }}>
        <Button
          color="primary"
          variant="outlined"
          style={{ marginRight: '1rem' }}
          onClick={closeDialog}
        >
          Close
        </Button>
      </DialogActions>
    </Flex>
  );
};

export default NotImportedJobs;
