import { IconButton, Typography } from '@mui/material';
import { Flex } from '@synthetica-ai/components';

const IconWithText = ({ icon, label, onClick }) => (
  <Flex onClick={onClick} alignItems="center">
    <IconButton sx={{ mr: '0.5rem' }}>
      {icon}
    </IconButton>

    <Typography sx={{ cursor: 'pointer' }}>
      {label}
    </Typography>
  </Flex>
);

export default IconWithText;
