import { Skeleton } from '@mui/material';
import { Flex } from '@synthetica-ai/components';

const ImageSkeletons = ({ images }) => (
  <Flex gap="2rem">
    {images?.map((_, index) => (
      <Skeleton
        key={index}
        variant="rectangular"
        width={80}
        height={80}
      />
    ))}
  </Flex>
);

export default ImageSkeletons;
