import { generateScopedActionCreator } from '@synthetica-ai/system';

const { createEffectAction } = generateScopedActionCreator('USER');

export const updateUser = createEffectAction('UPDATE_USER');
export const getAuthToken = createEffectAction('GET_AUTH_TOKEN');
export const authorizationError = createEffectAction('AUTHORIZATION_ERROR');
export const logout = createEffectAction('LOGOUT');
export const checkToken = createEffectAction('CHECK_TOKEN');
export const reIssueToken = createEffectAction('RE_ISSUE_TOKEN');
export const validateToken = createEffectAction('VALIDATE_TOKEN');
export const saveFailedRequests = createEffectAction('SAVE_FAILED_ACTIONS');
export const syncFailedRequests = createEffectAction('SYNC_FAILED_REQUESTS');
