import { memo } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useProps } from '@synthetica-ai/system';

const withLoadingCover = (selectors, createCondition) => (Component) => {
  const MemoizedComponent = memo(Component);
  return (props) => {
    const stateSelectors = useProps(selectors);
    const isLoading = createCondition(stateSelectors);

    return isLoading ? (
      <Box
        display="flex"
        flexDirection="column"
      >
        <Box
          margin="auto"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <CircularProgress />
          <Typography style={{ marginTop: '0.5rem' }}>Loading...</Typography>
        </Box>
      </Box>
    ) : (
      <MemoizedComponent {...props} /> || null
    );
  };
};

export default withLoadingCover;
