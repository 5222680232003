import ReactDOM from 'react-dom';
import { StrictMode } from 'react';

import Providers from 'providers';
import { theme } from 'assets/theme';
import App from './App';
import registerServiceWorker from './register-service-worker';

ReactDOM.render(
  <StrictMode>
    <Providers theme={theme}>
      <App />
    </Providers>
  </StrictMode>,
  document.getElementById('root'),
);

registerServiceWorker();
