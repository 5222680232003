import {
  Dialog as MuiDialog,
  DialogTitle,
} from '@mui/material';

import { withProps } from '@synthetica-ai/system';
import { closeDialog, dialog } from 'models/ui';
import {
  INITIATE_DRYDOCK,
  DRYDOCK_LIST,
  DELETE_JOB,
  ADD_JOB,
  OPEN_CAMERA,
  IMPORT_CSV,
  SHIPYARD_PRICES,
  ADD_OTHER_COST,
  EXPORT_CSV,
  EXPORT_OTHER_COSTS_CSV,
  DATE_PICKER,
  FINALIZE,
  TIMEPLAN,
  UPLOAD_FILE,
  NOT_IMPORTED_JOBS,
} from 'constants/dialog';

import {
  InitiateDrydock,
  DrydockList,
  DeleteJob,
  AddJob,
  Camera,
  ImportCsv,
  ShipyardPrices,
  AddOtherCost,
  ExportCsv,
  ExportOtherCostsCsv,
  DatePicker,
  Finalize,
  Timeplan,
  UploadFile,
  NotImportedJobs,
} from './components';

import { useStyles } from './styles';

const Dialog = ({ dialog, closeDialog }) => {
  const classes = useStyles();

  const handleClose = () => closeDialog();

  const Component = {
    [INITIATE_DRYDOCK]: InitiateDrydock,
    [DRYDOCK_LIST]: DrydockList,
    [DELETE_JOB]: DeleteJob,
    [ADD_JOB]: AddJob,
    [OPEN_CAMERA]: Camera,
    [IMPORT_CSV]: ImportCsv,
    [SHIPYARD_PRICES]: ShipyardPrices,
    [ADD_OTHER_COST]: AddOtherCost,
    [EXPORT_CSV]: ExportCsv,
    [EXPORT_OTHER_COSTS_CSV]: ExportOtherCostsCsv,
    [DATE_PICKER]: DatePicker,
    [FINALIZE]: Finalize,
    [TIMEPLAN]: Timeplan,
    [UPLOAD_FILE]: UploadFile,
    [NOT_IMPORTED_JOBS]: NotImportedJobs,
  }[dialog.type] || (() => null);

  return (
    <MuiDialog
      fullScreen={dialog.fullScreen}
      open={dialog.isOpen}
      className={classes.dialog}
      onClose={handleClose}
    >
      <div className={classes.container}>
        <DialogTitle>{dialog.title}</DialogTitle>

        <Component
          props={dialog.props}
          closeDialog={handleClose}
        />
      </div>
    </MuiDialog>
  );
};

export default withProps({
  dialog,
  closeDialog,
})(Dialog);
