import { Autocomplete, TextField } from '@mui/material';
import { startCase } from 'lodash';

import { withProps } from '@synthetica-ai/system';
import { vessels } from 'models/app';

const VesselInput = ({ value, vessels, onChange }) => (
  <Autocomplete
    value={value}
    getOptionLabel={(option) => startCase(option.name)}
    options={vessels}
    size="small"
    onChange={(_, value) => onChange(value)}
    renderInput={(params) => <TextField {...params} autoFocus variant="standard" size="small" />}
  />
);

export default withProps({
  vessels,
})(VesselInput);
