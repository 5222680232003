import { BASE_URL } from 'constants/origins';
import { createEndpoint, withCompanyAndToken } from 'utils/services';

export const getDrydock = withCompanyAndToken(({ payload: uuid }) => ({
  url: createEndpoint(BASE_URL, 'jobs', uuid),
  method: 'GET',
}));

export const updateDrydock = withCompanyAndToken(([uuid, payload]) => ({
  url: createEndpoint(BASE_URL, 'drydock', uuid),
  method: 'PUT',
  body: payload,
}));

export const deleteJob = withCompanyAndToken(({ payload }) => ({
  url: createEndpoint(BASE_URL, 'jobs'),
  method: 'DELETE',
  body: payload,
}));

export const addJob = withCompanyAndToken(([uuid, payload]) => ({
  url: createEndpoint(BASE_URL, 'drydock', uuid),
  method: 'POST',
  body: payload,
}));

export const finalizeDrydock = withCompanyAndToken((uuid) => ({
  url: createEndpoint(BASE_URL, 'drydock', 'finalize', uuid),
  method: 'POST',
}));

export const updateDiscount = withCompanyAndToken(([uuid, discount]) => ({
  url: createEndpoint(BASE_URL, 'drydock', 'discount', uuid),
  method: 'PUT',
  body: {
    discount,
  },
}));

export const uploadCsv = withCompanyAndToken(([payload, uuid]) => ({
  url: createEndpoint(BASE_URL, 'import', uuid),
  method: 'POST',
  body: {
    file: payload.file,
    filename: payload.filename,
  },
}));

export const getPaintAreas = withCompanyAndToken(({ payload }) => ({
  url: createEndpoint(BASE_URL, 'paint-areas', payload),
  method: 'GET',
}));

export const updateShipyardPrice = withCompanyAndToken(([payload, uuid]) => ({
  url: createEndpoint(BASE_URL, 'prices', uuid),
  method: 'POST',
  body: {
    shipyard: payload.shipyard,
    tariff: payload.tariff,
  },
}));

export const getOtherCostCategories = withCompanyAndToken(() => ({
  url: createEndpoint(BASE_URL, 'other-costs', 'categories'),
  method: 'GET',
}));

export const getOtherCosts = withCompanyAndToken((uuid) => ({
  url: createEndpoint(BASE_URL, 'other-costs', uuid),
  method: 'GET',
}));

export const updateOtherCosts = withCompanyAndToken(([payload, uuid]) => ({
  url: createEndpoint(BASE_URL, 'other-costs', uuid),
  method: 'PUT',
  body: payload,
}));

export const deleteOtherCosts = withCompanyAndToken(([payload, uuid]) => ({
  url: createEndpoint(BASE_URL, 'other-costs', uuid),
  method: 'DELETE',
  body: { ids: payload },
}));

export const exportOtherCostsCsv = withCompanyAndToken(([uuid, report]) => ({
  url: createEndpoint(BASE_URL, 'other-costs-export', uuid, report),
  method: 'GET',
}));
