import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  container: {
    height: '56px',
    display: 'flex',
    marginBottom: '10px',
    justifyContent: 'space-between',
    boxShadow: '0 1px 2px 0 rgba(201,201,201,0.5)',
    paddingLeft: '24px',
  },
  userName: {
    fontSize: '12px',
    lineheight: '14px',
    margin: 'auto',
    marginRight: '8px',
    textTransform: 'capitalize',
  },
  userIcon: {
    width: '40px',
    height: '40px',
    margin: 'auto',
    '& svg': {
      width: '40px',
      height: '40px',
      fill: '#003247',
    },
  },
}));
