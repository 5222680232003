import drydock from './reducer';
import drydockEpics from './epics';

export * from './selectors';
export * from './actions';

export const model = {
  reducers: {
    drydock,
  },
  epics: {
    drydockEpics,
  },
};
