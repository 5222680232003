import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Tabs, Tab, Popover, Typography,
} from '@mui/material';
import { AccountCircle } from '@mui/icons-material';

import { Flex } from '@synthetica-ai/components';
import { downloadTutorial, selectedRoute } from 'models/app';
import { withProps } from '@synthetica-ai/system';

import { logout } from 'models/user';
import { useStyles } from './styles';

const tabs = {
  0: '/',
  1: '/follow-up',
};

const NavigationBar = ({
  selectedRoute,
  routesList,
  logout,
  downloadTutorial,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const { push } = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'popover' : undefined;

  const onTabChange = (_, value) => {
    setValue(value);
    push(tabs[value]);
  };

  useEffect(() => {
    const path = routesList?.[selectedRoute]?.path;
    const value = Object.values(tabs).indexOf(path);
    setValue(value > 0 ? value : 0);
  }, [selectedRoute]);

  return (
    <Flex className={classes.container}>
      <div />
      <Tabs value={value} onChange={onTabChange}>
        <Tab label="Home" />
        <Tab label="Follow up" />
      </Tabs>

      <Flex style={{ marginRight: '24px' }}>
        <div className={classes.userIcon}>
          <AccountCircle style={{ cursor: 'pointer' }} onClick={handleClick} />
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Typography
              onClick={() => logout()}
              variant="body2"
              sx={{ p: '1rem 0.5rem', cursor: 'pointer' }}
            >
              Logout

            </Typography>
            <Typography
              onClick={() => downloadTutorial()}
              variant="body2"
              sx={{ p: '1rem 0.5rem', cursor: 'pointer' }}
            >
              Download DD Tutorial
            </Typography>
          </Popover>
        </div>
      </Flex>
    </Flex>
  );
};

export default withProps({
  selectedRoute,
  logout,
  downloadTutorial,
})(NavigationBar);
