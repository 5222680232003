import { Flex } from '@synthetica-ai/components';
import {
  Button,
  DialogActions,
  Typography,
} from '@mui/material';
import { fileListToBase64 } from 'utils/file';

const UploadFile = ({
  props,
  closeDialog,
}) => {
  const onChange = async (e) => {
    const { files } = e.target;
    const arrayOfBase64 = await fileListToBase64([files[0]]);
    const file = arrayOfBase64.map((value, index) => ({
      file: value,
      filename: files[index].name,
    }))[0];

    props.onChange(file);
  };

  return (
    <Flex center direction="column" style={{ width: '100%' }}>
      <Typography> Click Upload to choose file. </Typography>
      <DialogActions sx={{ ml: 'auto', mt: '1rem' }}>
        <Button
          color="primary"
          variant="outlined"
          style={{ marginRight: '1rem' }}
          onClick={closeDialog}
        >
          Cancel
        </Button>

        <Button
          variant="contained"
          component="label"
          color="secondary"
        >
          Upload
          <input
            hidden
            multiple
            type="file"
            onChange={onChange}
          />
        </Button>
      </DialogActions>
    </Flex>
  );
};

export default UploadFile;
