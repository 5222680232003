import { combineEpics, ofType } from 'redux-observable';
import {
  map,
  tap,
  ignoreElements,
  mergeMapTo,
} from 'rxjs/operators';
import download from 'downloadjs';

import { httpRequest } from '@synthetica-ai/system';
import { customServerError } from '@synthetica-ai/system/actions';
import { closeDialog, openSnackbar } from 'models/ui';
import { saveFailedRequests, syncFailedRequests } from 'models/user';

import { uploadCsv } from 'models/drydock';
import * as actions from './actions';
import * as services from './services';

const push = (source, url) => actions.redirect({ push: () => source.push(url) });

const startApplicationEpic = (action$) => action$.pipe(
  ofType(actions.startApplication.type),
  mergeMapTo([
    actions.getVessels(),
    actions.getDrydocks(),
    syncFailedRequests(),
  ]),
);

const getVesselsEpic = (action$) => action$.pipe(
  ofType(actions.getVessels.type),
  httpRequest(action$, services.vessels),
);

const getDrydocksEpic = (action$) => action$.pipe(
  ofType(actions.getDrydocks.type),
  httpRequest(action$, services.drydock),
);

const initiateDrydockEpic = (action$) => action$.pipe(
  ofType(actions.initiateDrydock.type),
  map(({ payload }) => {
    const {
      vessel,
      date,
      ...rest
    } = payload.data;

    return {
      ...rest,
      datetime_arrived: date,
      vessel_id: vessel.id,
    };
  }),
  httpRequest(action$, services.initiateDrydock,
    {
      additionalFailureActions: (payload) => [
        closeDialog(),
        openSnackbar({ type: 'error', text: payload }),
      ],
      additionalSuccessActions: ({ uuid, project_id }, source) => [
        closeDialog(),
        push(source, `drydock/${uuid}/${project_id}`),
      ],
    }),
);

const redirectEpic = (action$) =>
  action$.pipe(
    ofType(actions.redirect.type),
    tap(({ payload }) => payload.push()),
    ignoreElements(),
  );

const customServerErrorEpic = (action$) => action$.pipe(
  ofType(customServerError.type),
  map(({ payload }) => {
    const {
      status, message, action, service,
    } = payload;

    if (status === 0) {
      return saveFailedRequests(service);
    }

    return action.fail(message);
  }),
);

const exportCsvEpic = (action$, value$) => action$.pipe(
  ofType(actions.exportCsv.type),
  map(({ payload }) => {
    const { uuid } = value$.value.app;
    return [uuid, payload];
  }),
  // tap((data) => console.log(data)),
  httpRequest(action$, services.exportCsv, {
    additionalSuccessActions: () => [
      closeDialog(),
    ],
  }),
);

const exportSummaryEpic = (action$, value$) => action$.pipe(
  ofType(actions.exportSummary.type),
  map(({ payload }) => payload || value$.value.app.uuid),
  httpRequest(action$, services.exportSummary),
);

const exportTimeplanEpic = (action$, value$) => action$.pipe(
  ofType(actions.exportTimeplan.type),
  map(({ payload }) => payload || value$.value.worklist.details.uuid),
  httpRequest(action$, services.exportTimeplan),
);

const exportCsvSuccessEpic = (action$) => action$.pipe(
  ofType(
    actions.exportCsv.success.type,
    actions.exportSummary.success.type,
    actions.exportTimeplan.success.type,
    uploadCsv.success.type,
  ),
  tap(({ payload }) => {
    download(atob(payload.file), payload.filename, 'application/csv');
  }),
  ignoreElements(),
);

const downloadTutorialEpic = (action$) => action$.pipe(
  ofType(actions.downloadTutorial.type),
  httpRequest(action$, services.downloadTutorial),
);

export default combineEpics(
  startApplicationEpic,
  getVesselsEpic,
  getDrydocksEpic,
  initiateDrydockEpic,
  redirectEpic,
  customServerErrorEpic,
  exportCsvEpic,
  exportTimeplanEpic,
  exportSummaryEpic,
  exportCsvSuccessEpic,
  downloadTutorialEpic,
);
