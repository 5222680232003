import { useState } from 'react';
import { TextField, Button, DialogActions } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { uploadCsv, isLoading } from 'models/drydock';

import { withProps } from '@synthetica-ai/system';
import { fileListToBase64 } from './helper';

const ImportCsv = ({ closeDialog, props, isLoading }) => {
  const [file, setFile] = useState(null);

  function onChange(e) {
    const file = e.target.files;
    setFile(file?.[0]);
  }
  const uploadFile = async () => {
    const arrayOfBase64 = await fileListToBase64(file);
    const files = arrayOfBase64.map((value) => ({
      file: value,
      filename: file.name,
    }))[0];
    props.onClick(files);
  };

  return (
    <div>
      <TextField
        type="file"
        onChange={onChange}
        size="small"
        style={{ maxWidth: '30ch' }}
      />
      <DialogActions sx={{ ml: 'auto', mt: '1rem' }}>
        <Button
          color="primary"
          variant="outlined"
          onClick={closeDialog}
        >
          Close
        </Button>
        <LoadingButton
          loading={isLoading}
          color="primary"
          variant="contained"
          disabled={!file}
          onClick={() => uploadFile()}
        >
          Upload
        </LoadingButton>

      </DialogActions>
    </div>

  );
};

export default withProps({ uploadCsv, isLoading })(ImportCsv);
