import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import Dialog from 'components/Dialog';
import Snackbar from 'components/Snackbar';
import NavigationBar from 'components/NavigationBar';

import routes, { routesList } from './config';

const Routes = () => (
  <Router>
    <Dialog />
    <Snackbar />
    <NavigationBar routesList={routesList} />
    <Switch>
      {Object.values(routes)
        .filter(({ enabled }) => enabled)
        .map((routeProps, index) => (
          <Route key={index} {...routeProps} />
        ))}

      <Route
        path="*"
        render={() => <Redirect to="/" />}
      />
    </Switch>
  </Router>
);

export default Routes;
