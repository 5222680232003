import {
  Snackbar as MaterialSnackbar,
  Alert,
  Slide,
  Button,
} from '@mui/material';

import { Flex } from '@synthetica-ai/components';
import { withProps } from '@synthetica-ai/system';
import { closeSnackbar, snackbar } from 'models/ui';

function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}

const Snackbar = ({ snackbar, closeSnackbar }) => {
  const handleClose = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    closeSnackbar();
  };

  return (
    <MaterialSnackbar
      open={snackbar.isOpen}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={snackbar.static ? null : 3000}
      TransitionComponent={TransitionLeft}
      onClose={handleClose}
    >
      <Alert
        elevation={6}
        variant="filled"
        onClose={handleClose}
        severity={snackbar.type}
      >
        <Flex gap="0.5rem" alignItems="center">
          <div>
            {snackbar.text}
          </div>

          {snackbar.action
            && (
              <Button variant="contained" onClick={() => snackbar.action?.onClick()}>
                {snackbar.action?.label}
              </Button>
            )}
        </Flex>
      </Alert>
    </MaterialSnackbar>
  );
};

export default withProps({
  snackbar,
  closeSnackbar,
})(Snackbar);
