import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  cell: {
    height: '56px',
    borderBottom: '0',
    padding: '16px 24px',
  },
  cell2: {
    padding: '8px 24px',
    borderBottom: '0',
    backgroundColor: '#d3d3d3',
  },
  head: {
    backgroundColor: '#F7F7F7',
  },
  headCell: {
    height: '48px',
    padding: '8px 24px',
    borderBlock: '1px solid rgba(224, 224, 224, 1)',
  },
  icon: {
    width: '16px !important',
    height: '16px !important',
    marginLeft: '8px',
  },
}));
