import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';

const initialState = {
  info: {},
  isAuthenticated: false,
};

const reducer = createReducer(initialState, (builder) => builder
  .addCase(actions.updateUser, (state, { payload }) => {
    state.info = payload.info;
    state.isAuthenticated = payload.isAuthenticated;
  }));

export default reducer;
