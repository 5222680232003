import { generateScopedActionCreator } from '@synthetica-ai/system';

const { createEffectAction, createAction } = generateScopedActionCreator('WORKSLIST');

export const getDrydocksDetails = createEffectAction('GET_DRYDOCK_DETAILS');
export const updateJob = createEffectAction('UPDATE_JOB');
export const updateJobs = createEffectAction('UPDATE_JOBS');
export const uploadImages = createEffectAction('UPLOAD_IMAGES');
export const deleteImage = createEffectAction('DELETE_IMAGE');
export const getJobImages = createEffectAction('GET_JOB_IMAGES');
export const setDrydockDetails = createEffectAction('SET_DRYDOCK_DETAILS');
export const updateProjectDetails = createEffectAction('UPDATE_PROJECT_DETAILS');
export const addJob = createEffectAction('ADD_JOB');
export const getShipyardFiles = createEffectAction('GET_SHIPYARD_FILES');
export const deleteFile = createEffectAction('DELETE_FILE');
export const uploadShipyardFiles = createEffectAction('UPLOAD_SHIPYARD_FILES');
export const getDDTimes = createEffectAction('GET_DD_TIMES');
export const updateDDTimes = createEffectAction('UPDATE_DD_TIMES');
export const setTableTimes = createEffectAction('UPDATE_TIMES');
export const fillOffhireData = createAction('FILL_OFFHIRE_DATA');
export const finalizeOffhireData = createAction('FINALIZE_OFFHIRE_DATA');
export const downloadFile = createEffectAction('DOWNLOAD_FILE');
export const uploadTimeplanFile = createEffectAction('UPLOAD_TIMEPLAN_FILE');
export const downloadTimeplanFile = createEffectAction('DOWNLOAD_TIMEPLAN_FILE');
export const deleteTimeplanFile = createEffectAction('DELETE_TIMEPLAN_FILE');
export const finalizeDD = createEffectAction('FINALIZE_DD');
export const getHistoryJobs = createEffectAction('GET_HISTORY_JOBS');
export const revertHistory = createEffectAction('REVERT_HISTORY');
