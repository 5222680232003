import ReactCamer, { FACING_MODES } from 'react-html5-camera-photo';
import { Button, DialogActions } from '@mui/material';
import 'react-html5-camera-photo/build/css/index.css';

import { Flex } from '@synthetica-ai/components';

const Camera = ({ props, closeDialog }) => (
  <Flex direction="column">
    <ReactCamer
      isImageMirror={false}
      idealFacingMode={FACING_MODES.ENVIRONMENT}
      onTakePhoto={(dataUri) => props.onClick(dataUri)}
    />
    <DialogActions sx={{ ml: 'auto', mt: '1rem' }}>
      <Button
        color="primary"
        variant="outlined"
        onClick={closeDialog}
      >
        Close
      </Button>
    </DialogActions>
  </Flex>
);

export default Camera;
