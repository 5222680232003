import { useState } from 'react';
import { withProps } from '@synthetica-ai/system';
import { TextField, Button, DialogActions } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { deleteTimeplanFile, downloadTimeplanFile, uploadTimeplanFile } from 'models/worklist';
import { fileListToBase64 } from 'utils/file';
import ImageGallery from 'components/ImageGallery';

const Timeplan = ({
  closeDialog,
  downloadTimeplanFile,
  uploadTimeplanFile,
  deleteTimeplanFile,
  props,
}) => {
  const [file, setFile] = useState(null);
  console.log({ props });
  function onChange(e) {
    const file = e.target.files;
    setFile(file?.[0]);
  }
  const uploadFile = async () => {
    const arrayOfBase64 = await fileListToBase64([file]);
    const files = arrayOfBase64.map((value, index) => ({
      file: value,
      filename: [file][index].name,
      datetime: props.datetime,

    }));

    uploadTimeplanFile(files);
  };

  return (
    <div>
      <TextField
        type="file"
        onChange={onChange}
        size="small"
        style={{ maxWidth: '30ch', marginBottom: '1rem' }}
      />
      {props.files
        && (
          <ImageGallery
            onClick={(id) => downloadTimeplanFile(id)}
            images={props.files}
            deleteItem={deleteTimeplanFile}
            hasEditRights
          />
        )}
      <DialogActions sx={{ ml: 'auto', mt: '1rem' }}>
        <Button
          color="primary"
          variant="outlined"
          onClick={closeDialog}
        >
          Close
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          disabled={!file}
          onClick={() => uploadFile()}
        >
          Upload
        </LoadingButton>

      </DialogActions>
    </div>

  );
};

export default withProps({
  uploadTimeplanFile,
  downloadTimeplanFile,
  deleteTimeplanFile,
})(Timeplan);
